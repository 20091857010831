export const SPONSOR_REACH = "SPONSOR_REACH";
export const SPONSOR_VALUE = "SPONSOR_VALUE";
export const TV_ENGAGEMENT = "TV_ENGAGEMENT";
export const BRAND_SOURCES = "BRAND_SOURCES";
export const PARTNER_DIVIDED_REACH = "PARTNER_DIVIDED_REACH";
export const PARTNER_VALUE = "PARTNER_VALUE";
export const PARTNER_DIVIDED_VALUE = "PARTNER_DIVIDED_VALUE";
export const PARTNER_REACH = "PARTNER_REACH";
export const SPONSOR_DIVIDED_REACH = "SPONSORDIVIDEDREACH";
export const SPONSOR_DIVIDED_VALUE = "SPONSORDIVIDEDVALUE";
export const SPONSOR_STATION_DIVIDED_VALUE = "SPONSORSTATIONDIVIDEDVALUE";
export const SPONSOR_VALUE_PER_SOURCE = "SPONSOR_VALUE_PER_SOURCE";
export const SPONSOR_LOGO_STATS = "SPONSOR_LOGO_STATS";
export const PARTNER_VALUE_PER_SOURCE = "PARTNER_VALUE_PER_SOURCE";
export const ENGAGEMENT_CHANNELS = "ENGAGEMENT_CHANNELS";
export const PARTNER_ENGAGEMENT = "PARTNER_ENGAGEMENT";
export const PARTNER_ENGAGEMENT_VALUE = "PARTNER_ENGAGEMENT_VALUE";
export const SPONSOR_DIVIDED_ENGAGEMENT = "SPONSOR_DIVIDED_ENGAGEMENT";
export const SPONSOR_DIVIDED_ENGAGEMENT_VALUE = "SPONSOR_DIVIDED_ENGAGEMENT_VALUE";
export const PARTNERS = "PARTNERS";
export const TENANTS = "TENANTS";
export const MERGED = "MERGED";
export const DATA_SOURCES = "DATA_SOURCES";
export const BOXED_METRIC = "BOXED_METRIC";
export const ATHLETE = "ATHLETE";
export const PERIOD = "PERIOD";
export const REACH = "REACH";
export const SOCIAL_MEDIA_POST_WIDGET = "SOCIAL_MEDIA_POST_WIDGET";
export const PERFORMANCE_INDEX = "PERFORMANCE_INDEX";
export const MEDIA_VALUE_PERIOD = "MEDIA_VALUE_PERIOD";
export const SPONSOR_BRAND_VALUE = "SPONSOR_BRAND_VALUE";
export const IMAGE_DETECTION_EXAMPLE = "IMAGE_DETECTION_EXAMPLE";
export const PERSON_SOCIAL_METRICS = "PERSON_SOCIAL_METRICS";
export const TENANT_PROFILE_DATA = "TENANT_PROFILE_DATA";
export const ALL_BRANDS = "ALL_BRANDS";