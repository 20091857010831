import React from "react";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import ApiService from "../services/ApiService";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";

const NextMatch = ({ tenant }) => {
  const { t } = useTranslation();
  const { data, isLoading, isRefetching, isSuccess } = useQuery({
    queryKey: ["nextmatch", tenant.id],
    queryFn: ApiService.getNextMatch,
    select: (data) => data.data,
    placeholderData: {},
  });

  return (
    <Card className="mini-stat bg-card text-white h-100">
      <CardBody>
        <div className="mb-4">
          <h4 className="card-title mb-0">{t("Neste hjemmekamp")}</h4>
        </div>
        {(isLoading || isRefetching) && (
          <Spinner
            color="primary"
            size="sm"
            style={{ position: "absolute", right: 20 }}
          />
        )}
        {isSuccess && (
          <>
            <img
              src={data.logo}
              className="mx-auto d-block img-fluid mb-3"
              alt={data.opponent}
            />
            <h6 className="text-center text-uppercase mb-3">{data.opponent}</h6>
            <Row>
              <Col sm={8} className="text-warning text-left">
                {t("Ligaposisjon")}
              </Col>
              <Col sm={4} className="text-warning text-right">
                {data.position}
              </Col>
            </Row>
            <Row>
              <Col sm={8} className="text-warning text-left">
                {t("Snitt publikum siste 5")}
              </Col>
              <Col sm={4} className="text-warning text-right">
                {data.averageAudience}
              </Col>
            </Row>
          </>
        )}
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(NextMatch);
