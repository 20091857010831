import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, Spinner } from 'reactstrap';
import OverallMetric from '../components/OverallMetric';
import { useTranslation } from 'react-i18next';
import FollowersGraph from '../components/FollowersGraph';
import VisibilityGraph from '../components/VisibilityGraph';
import Profile from '../components/Profile';
import ApiService from '../services/ApiService';

const PersonDash = () => {
  const { t } = useTranslation();

  const [profile, setProfile] = useState({ image: '', name: '' });
  const [imageLoaded, setImageLoaded] = useState(false); // State to track image loading
  var name = '';
  var imageUrl = '';
  
  useEffect(() => {
    const fetchData = async () => {
      const data = await ApiService.getProfileData(); // Replace with your actual API call
      setProfile(data);
      console.log(data);
      setImageLoaded(true);
    };
    

    fetchData();
  }, []);

  console.log(profile);

  try {
    name = profile.data.name;
    imageUrl = profile.data.imageUrl;
  } catch (error) {
    console.log(error);
  }

  const handleImageLoad = () => {
    // This function will be called when the image is loaded
    setImageLoaded(true);
  };

  return (
    <>

      {imageLoaded ? (
        <Profile src={imageUrl} alt="circle_image" onLoad={handleImageLoad} />
      ) : (
        <Spinner 
          alt="circle_image" 
          color="primary"
          size="sm"
          style={{ position: "absolute", right: 20 }}
        />
      )}
      <Row className="person-box">
        <Col xs={12} className="person-box mb-lg-0">
          <h1 className="profile-name">{name}</h1>
        </Col>
      </Row>
      <Row className="person-box">
        <Col xs={6} className="person-box mb-lg-0">
          <OverallMetric noTrend metric="followers" />
        </Col>
        <Col xs={6} className="person-box mb-lg-0">
          <OverallMetric noTrend metric="visibility" />
        </Col>
      </Row>
      <Row className="person-box">
        <Col xs={6} className="person-box mb-lg-0">
          <OverallMetric noTrend metric="partner-value" />
        </Col>
        <Col xs={6} className="person-box mb-lg-0">
          <OverallMetric noTrend metric="engagement-this-year" nosubscript />
        </Col>
      </Row>

      <Row className="person-box">
        <Col sm={12} md={6}>
          <Card className="mini-stat bg-card text-white">
            <CardBody>
              <h4 className="card-title person-box">
                {t('Følgere')}
              </h4>
              <FollowersGraph />
            </CardBody>
          </Card>
        </Col>

        <Col sm={12} md={6}>
          <Card className="mini-stat bg-card text-white divider">
            <CardBody>
              <h4 className="card-title person-box">
                {t('Synlighet')}
              </h4>
              <VisibilityGraph />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps, {})(PersonDash);
