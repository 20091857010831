import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { ATHLETE, PERIOD } from "../keys";

const useAthleteMetric = (metric) => {
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);

  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }
  return useQuery({
    queryKey: [ATHLETE, PERIOD, metric, tenant.id, startDate, endDate],
    queryFn: () => ApiService.getAthleteMetric(metric, since, until),
    select: (data) => data?.data,
    placeholderData: { data: {} },
  });
};
export default useAthleteMetric;
