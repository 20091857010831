var API_URL = process.env.NODE_ENV === 'development' ?
    "http://api.foqus.spect8.local:8890" : "https://api.app.foocus.ai";

    //"http://api.foqus.spect8.local:8890" : "https://api.foocus.spect8.no";

//API_URL= "https://lionfish-app-auidg.ondigitalocean.app";
// API_URL = "https://api.foocus.spect8.no";
//API_URL= "https://api.foqus.spect8.no";
//API_URL = "https://api.app.foocus.ai";


const caching = false; // Enable caching of data
const chat_active = false; // Enable chatbot

export const Globals = {
    API_URL,
    chat_active,
    caching
}