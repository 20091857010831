import React from "react";
import ReactFullscreen from 'react-easyfullscreen';

const FullscreenBtn = () => {
    
    return (
      <ReactFullscreen>
        {({ ref, onToggle, exit }) => (
          <>
            <button className="ti-fullscreen" onClick={() => onToggle()} />
          </>
        )}
      </ReactFullscreen>
    );
    
};
export default FullscreenBtn;