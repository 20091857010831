export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const CREATE_AUTH_CLIENT = 'CREATE_AUTH_CLIENT'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_LOADING = 'SET_LOADING'
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED'
export const SET_USER = 'SET_USER'
export const SET_USER_TENANT = 'SET_USER_TENANT'

export const FETCH_USER_TENANTS_PENDING = 'FETCH_USER_TENANTS_PENDING'
export const FETCH_USER_TENANTS_SUCCESS = 'FETCH_USER_TENANTS_SUCCESS'
export const FETCH_USER_TENANTS_ERROR = 'FETCH_USER_TENANTS_ERROR'

export const SET_DATE = 'SET_DATE';

export const SET_SELECTED_CURRENCY = 'SET_SELECTED_CURRENCY';
export const SET_CURRENCIES = 'SET_CURRENCIES';

export const SET_TEST = 'SET_TEST'