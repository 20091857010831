import React, { Component } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import { Switch, Router } from 'react-router-dom'
import AppRoute from './routes/AppRoute'
import history from './utils/history'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setUserTenant, setIsAuthenticated, setLoading, createAuthClient, loginUser, logoutUser, getUser, setAccessToken } from './store/actions/authActions';
import { setCurrencies } from './store/actions/currencyActions';

import fetchTenants from './store/actions/fetchTenants'

import { authProtectedRoutes, publicRoutes } from "./routes/";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ApiService from './services/ApiService'

import 'react-chat-widget/lib/styles.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      staleTime: 1000 * 60, // 1 Minute
      cacheTime: 1000 * 60 * 60 * 24 * 7 * 3, // 3 Weeks
      refetchOnWindowFocus: false,
    },
  },
});

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});



class App extends Component {
  componentDidMount() {
    // Let's try to login the user here
    const initAuth0 = async () => {
      this.props.setLoading(true);
      const auth0Client = await createAuth0Client({
        domain: this.props.domain,
        client_id: this.props.client_id,
        audience: this.props.audience,
        redirect_uri: window.location.origin + window.location.search,
      });

      this.props.createAuthClient(auth0Client);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0Client.handleRedirectCallback();
        this.props.onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0Client.isAuthenticated();

      console.log("isAuthenticated", isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0Client.getUser();
        const access_token = await auth0Client.getTokenSilently();
        this.props.setAccessToken(access_token);

        // The user is logged in, let's fetch the tenants they have access to
        this.props.loginUser(user);
        await this.props.fetchTenants();

        const resConvertion = await ApiService.getCurrencyConvertion();
        this.props.setCurrencies(resConvertion.data.conversion_rates);

        this.props.getUser()
      }
      this.props.setIsAuthenticated(isAuthenticated);
      this.props.setLoading(false);
    };
    initAuth0();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tenants.length === 0 && this.props.tenants.length > 0 && this.props.tenant === null) {
      const { tenants } = this.props;
      var tenant;
      if (localStorage.getItem('selectedTenantId') === 'undefined' || localStorage.getItem('selectedTenantId') === null) {
        tenant = tenants.find(tenant => tenant.id === parseInt(tenants[0].id));
      } else {
        tenant = tenants.find(tenant => tenant.id === parseInt(localStorage.getItem('selectedTenantId')));
      }
      this.props.setUserTenant(tenant);
    }
  }

  render() {
    return (
      <>
         {/* {
         Globals.chat_active === true ? 
                    (<Widget
                    handleNewUserMessage={handleNewUserMessage}
                    profileAvatar={avatar}
                    titleAvatar={avatar}
                    title="K.I.M"
                    subtitle=""
                    showTimeStamp={false}
                    emojis={false}
                    senderPlaceHolder="Skriv et spørsmål her..."
                    resizable={false}
                />) : null} */}

        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister: localStoragePersister }}
        >
          <ReactQueryDevtools initialIsOpen={false} />
          <Router history={history}>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={route.layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                />
              ))}
              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={route.layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                />
              ))}
            </Switch>
          </Router>
        </PersistQueryClientProvider>
      </>


    );
  }
}
App.propTypes = {
    createAuthClient: PropTypes.func.isRequired,
    loginUser: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    setUserTenant: PropTypes.func.isRequired,
    fetchTenants: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    tenants: state.auth.tenants,
    tenant: state.auth.tenant,
})
export default connect(
    mapStateToProps,
    {
        setUserTenant,
        fetchTenants,
        setIsAuthenticated,
        getUser,
        setLoading,
        setAccessToken,
        createAuthClient,
        loginUser,
        logoutUser,
        setCurrencies,
    }
)(App)