import React from 'react'
import { Card, CardBody, Spinner } from 'reactstrap';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import solidGauge from "highcharts/modules/solid-gauge";
import { usePerformanceIndex } from '../query/hooks';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

HighchartsMore(Highcharts);
solidGauge(Highcharts);
const PerformanceIndex = () => {
  const { t } = useTranslation();
  const {data, isLoading, isRefetching} = usePerformanceIndex();
  const options = {
    credits: { enabled: false },
    chart: {
        type: 'solidgauge',
        height: 150,
    },
    title: null,
    pane: {
        center: ['50%', '85%'],
        size: '150%',
        startAngle: -90,
        endAngle: 90,
        background: {
            backgroundColor: '#555361',
            borderRadius: 5,
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc',
            borderColor: 'transparent'
        }
    },

    exporting: {
        enabled: false
    },
    tooltip: {
        enabled: false
    },
    yAxis: {
      stops: [
        [0.1, '#E4BE50'], // green
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 0,
      labels: {
        y: 16,
        style: {
          color: 'white',
          fontSize: '16px',
        }
      },
      min: 0,
      max: 100,
    },

    series: [{
      name: 'Performance Index',
      data: [27],
      dataLabels: {
        format:
        '<div style="text-align:center">' +
        '<span style="font-size:24px; font-weight: normal;">{y}</span><br/>' +
        '</div>',
        style: {
          color: 'white',
        },
        y: -40,
      },
    }],

    plotOptions: {
      solidgauge: {
        borderRadius: 3,
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
        }
      }
    }
  };

  return (
    <Card>
      <CardBody>
        {(isLoading || isRefetching) && <Spinner color="primary"></Spinner>}
        <h2 className="card-title mb-4">{t('Performance Index')}</h2>
        <div className="mb-2">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
        <div className="mb-4" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          <div style={{width: '100%', maxWidth: 400}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <span>{t('Total Followers')}</span>
              <span>{numeral(data?.totalFollowers ?? 0).format('0.0a')}</span>
            </div>
            <hr width="100%"/>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <span>{t('Profile Reach')}</span>
              <span>{((data?.profileReach ?? 0) * 100).toFixed(2)}%</span>
            </div>
            <hr width="100%"/>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <span>{t('Audience Growth Rate')}</span>
              <span>{((data?.audienceGrowthRate ?? 0) * 100).toFixed(2)}%</span>
            </div>
            <hr width="100%"/>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <span>{t('Post Frequency')}</span>
              <span>{Math.floor(data?.postFrequencyPerMonth ?? 0)} {t('per month')}</span>
            </div>
            <hr width="100%"/>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <span>{t('Engagement Rate')}</span>
              <span>{((data?.engagementRate ?? 0) * 100).toFixed(2)}%</span>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
};

export default PerformanceIndex;