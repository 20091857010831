import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, CardBody } from 'reactstrap'
import OverallMetric from "../components/OverallMetric";
import { useTranslation } from "react-i18next";
import FollowersGraph from "../components/FollowersGraph";
import VisibilityGraph from "../components/VisibilityGraph";
import EngagementGraph from "../components/EngagementGraph";
import PerformingSocialMediaPosts from "../components/PerformingSocialMediaPosts";
import { useEffect, useState } from "react";
import ApiService from "../services/ApiService";
import ValueChartPerson from '../components/ValueChartPerson';

const PersonSocials = () => {
    const { t } = useTranslation();

    const [, setProfile] = useState({ image: '', name: 'Petter Solberg' });

    useEffect(() => {
        const fetchData = async () => {
            const data = await ApiService.getProfileData(); // Replace with your actual API call
            setProfile(data);
        };

        fetchData();
    }, []);

    return (
        <>
            <Row className="mb-4">
            <Col sm={12} md={6}>
                <Card className="mini-stat bg-card text-white">
                    <CardBody>
                        <h4 className="card-title mb-4">
                        {t("Følgere")}
                        </h4>
                        <FollowersGraph />
                    </CardBody>
                </Card>
            </Col>

            <Col sm={12} md={6}>
                <Card className="mini-stat bg-card text-white divider">
                    <CardBody>
                        <h4 className="card-title mb-4">
                        {t("Synlighet")}
                        </h4>
                        <VisibilityGraph />
                    </CardBody>
                </Card>
            </Col>
            </Row>

            <Row className="mb-4">
                <Col xs={6} className="mb-4 mb-lg-0">
                    <OverallMetric
                    noTrend
                    metric="visibility-average-facebook"
                    nosubscript
                    value
                    />
                </Col>
                <Col xs={6} className="mb-4 mb-lg-0">
                    <OverallMetric
                    noTrend
                    metric="visibility-average-instagram"
                    nosubscript
                    value
                    />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col sm={12} md={6}>

                    <Card className="mini-stat bg-card text-white">
                        <CardBody>
                            <h4 className="card-title mb-4">
                            {t("Engasjement")}
                            </h4>
                            <EngagementGraph />
                        </CardBody>
                    </Card>
                </Col>
              
                <Col sm={12} md={6}>
                    <Card className="mini-stat bg-card text-white divider">
                        <CardBody>
                            <h4 className="card-title mb-4">
                            {t("Verdi")}
                            </h4>
                            <ValueChartPerson
                            defaultActiveTab={0}
                                />                    
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <u>
                                The graph shows the value of the overall amount of impressions and engagement you have created 
                            </u>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col md={12}>
                    <PerformingSocialMediaPosts type="BESTE" />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col md={12}>
                    <PerformingSocialMediaPosts type="VERSTE" />
                </Col>
            </Row>
        </>
    );
}

const mapStateToProps = state => ({
  tenant: state.auth.tenant
})

export default connect(mapStateToProps, {})(PersonSocials)
