
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { logoutUser } from '../store/actions/authActions'



class ProfileMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menu: false
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            menu: !prevState.menu
        }))
    }

    logout = (e) => {
        e.preventDefault()
        this.props.client.logout()
    }

    render() {
        console.log('ProfileMenu :: render()')

        if (!this.props.user)
            return (<></>)

        const {picture, name} = this.props.user

        return (
            <>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img className="rounded-circle header-profile-user" src={picture} alt={name} />
                    </DropdownToggle>
                    <DropdownMenu right>
                        {/*<DropdownItem tag="a" href="#"><i className="mdi mdi-account-circle font-size-17 align-middle mr-1"></i>Profile</DropdownItem>
                        <div className="dropdown-divider"></div>*/}
                        <button
                            onClick={this.logout}
                            className="dropdown-item">
                            <i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
                            <span>Logg ut</span>
                        </button>
                    </DropdownMenu>
                </Dropdown>
            </>
        );
    }
}

ProfileMenu.propTypes = {
    user: PropTypes.object
}
  
const mapStateToProps = state => ({
    user: state.auth.user,
    client: state.auth.client
})
  
export default connect(mapStateToProps, { logoutUser })(ProfileMenu)