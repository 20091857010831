import React, { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* Components */
import ApiService from '../services/ApiService'

const SponsorObjectTabs = (props) => {
    const [tabs, setTabs] = useState([])
    const { data } = props
    const [activeTab, setActiveTab] = useState(0)
    const [loaded, setLoaded] = useState(false)
   
    const { t } = useTranslation()

    useEffect(() => {
        const fetchSponsorObjects = async () => {
            ApiService.getSponsorObjects()
                .then(res => {
                    const data = res.data
                    setTabs(data)
                    setLoaded(true)
                }
            )
        }

        fetchSponsorObjects()
    }, [data])

    function toggleTab(tabIndex, sponsorObjectId) {
        if (activeTab !== tabIndex) {
            setActiveTab(tabIndex)
            
            props.onClickTab(tabIndex, sponsorObjectId)
        }
    }

    function renderTab(tab, index) {
        return (
            <NavItem key={index+1}>
                <NavLink
                    href="#"
                    className={classnames({
                        active: activeTab === index+1
                    })}
                    onClick={() => {
                        toggleTab(index+1, tab.id)
                    }}
                >
                    {tab.name}
                </NavLink>
            </NavItem>
        )
    }



    if (!loaded)
    return (<></>)
    
    return (
        <>
            <Nav tabs className="nav-tabs-custom mb-4">
                <NavItem>
                    <NavLink
                        className={classnames({
                            active: activeTab === 0
                        })} 
                        onClick={() => {
                            toggleTab(0, 0)
                        }}
                    >
                        <span className="d-none d-sm-block">{t('Sum')}</span>
                    </NavLink>
                </NavItem>
                {tabs.map((tab, index) => renderTab(tab, index))}
                <NavItem>
                    <NavLink
                        className={classnames({
                        active: activeTab === (tabs.length + 1)
                        })}
                        onClick={() => {
                            toggleTab(tabs.length + 1, null)
                        }}
                    >
                        <span className="d-none d-sm-block"><i className="mdi mdi-plus-box-multiple-outline"></i>{" "}{t('Add source')}</span>
                    </NavLink>
                </NavItem>
            </Nav>
        </>
    )
}


SponsorObjectTabs.propTypes = {
    onClickTab: PropTypes.func.isRequired
}

export default SponsorObjectTabs