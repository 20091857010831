import React from 'react'
import { Card, CardBody, Spinner } from 'reactstrap'
import classNames from 'classnames'
import ApiService from '../services/ApiService'
import { useTranslation } from 'react-i18next'
import InfoTooltip from './InfoTooltip'
import numeral from 'numeral'
import 'numeral/locales'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import RatioBar from './RatioBar'
import { useQuery } from '@tanstack/react-query'
numeral.locale('no')

const OverallMetric = ({
  metric,
  params,
  date,
  followCalender,
  append,
  tooltip,
  numeralFormat = "0,0",
  noTrend,
  variant,
  isCurrency = false,
  selectedCurrency,
  tenant,
  nosubscript,
  value,
}) => {
  let since = new Date().getFullYear() + "-01-01";
  let until = new Date().getFullYear() + "-12-31";
  if (followCalender && date.startDate && date.endDate) {
    since = format(new Date(date.startDate), "yyyy-MM-dd");
    until = format(new Date(date.endDate), "yyyy-MM-dd");
  }
  const { t } = useTranslation();
  const {
    data: metrics,
    isLoading,
    isRefetching,
    isSuccess,
  } = useQuery({
    queryKey: ["metric", tenant.id, metric, since, until, params],
    queryFn: () => ApiService.getMetric(metric, params, since, until),
    select: (data) => data.data,
    placeholderData: { data: {} },
  });

  const appended = append ? ` ${append}` : "";
  const trendingUp = metrics?.trending === "up";

  const renderTooltip = () => {
    if (tooltip) {
      return <InfoTooltip tooltip={tooltip} />;
    } else {
      return <></>;
    }
  };

  const renderVariant = () => {
    switch (variant) {
      case "ratio-bar":
        return (
          <RatioBar
            positive={metrics?.positive || 0}
            neutral={metrics.neutral || 0}
            negative={metrics.negative || 0}
          />
        );

      default:
        return (
          <h4 className="font-weight-medium font-size-24">
            {numeral(
              isCurrency
                ? metrics.value * selectedCurrency.value
                : metrics.value
            ).format(numeralFormat)}
            {isCurrency && ` ${selectedCurrency.currency}`}
            {appended}{" "}
            {noTrend ? (
              ""
            ) : (
              <i
                className={classNames({
                  mdi: true,
                  "ml-2": true,
                  "mr-1": true,
                  "mdi-arrow-down": !trendingUp,
                  "text-danger": !trendingUp,
                  "mdi-arrow-up": trendingUp,
                  "text-success": trendingUp,
                })}
              ></i>
            )}
            <span
              className={classNames({
                "font-size-16": true,
                "text-danger": !trendingUp,
                "text-success": trendingUp,
              })}
            >
              {}
            </span>
          </h4>
        );
    }
  };

  return (
    <>
      <Card className="mini-stat bg-card text-white h-100">
        <CardBody className="overall-metric">
          <div>
            <h5 className="font-size-16 text-uppercase mt-0 text-white-50 engagement-average">
              {metrics?.title}
              {isLoading || isRefetching ? (
                <Spinner color="primary" size="sm" />
              ) : (
                <></>
              )}
              {renderTooltip()}
            </h5>
            {isSuccess && (
              <>
                {renderVariant()}
                {!nosubscript && metrics.subscript && (
                  <h4 className="font-weight-light font-size-16 text-white-50">
                    {metrics.subtitle}
                    {appended}
                    {numeral(metrics.subscript).format(numeralFormat)}
                    <span
                      className={classNames({
                        "font-size-16": true,
                        "text-danger": !trendingUp,
                        "text-success": trendingUp,
                      })}
                    >
                      {}
                    </span>
                  </h4>
                )}
                {value && (
                  <h4 className="font-weight-light font-size-16 text-white-50">
                    {t("Verdi")}: {numeral(
                            metrics.value * 4.7 * selectedCurrency.value
                          ).format("0.0a")}
                  </h4>
                )}
              </>
            )}

          </div>
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = state => ({
	date: state.date,
	user: state.auth.user,
	client: state.auth.client,
	selectedCurrency: state.currency.selectedCurrency,
	tenant: state.auth.tenant,
})

export default connect(mapStateToProps)(OverallMetric)