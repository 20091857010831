import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { Globals } from "../Globals";
import { useTranslation } from "react-i18next";

const SettingDropdown = ({ tenant }) => {
  const { t } = useTranslation();
  const [setting_menu, setSettingMenu] = useState(false);
  const [isExpired, setisExpired] = useState(false);

  useEffect(() => {
    const expiredOrNot = async (tenantId) => {
      const resIsExpired = await axios.get(
        `${Globals.API_URL}/api/data-sources/is-tenant-expired/${tenantId}`
      );
      setisExpired(resIsExpired.data === "expired");
    };
    expiredOrNot(tenant.id);
  }, [tenant.id]);

  return (
    <>
      <Dropdown
        isOpen={setting_menu}
        toggle={() => setSettingMenu({ setting_menu: !setting_menu })}
      >
        <div style={{position: 'relative'}}>
          <DropdownToggle
            className="btn header-item noti-icon waves-effect"
            tag="button"
          >
            <i className="mdi mdi-settings-outline"></i>
          </DropdownToggle>
          {isExpired && <i className="mdi mdi-alert-circle" />}
        </div>
        <DropdownMenu right>
          <DropdownItem tag={Link} to="/data-sources">
            {t("Data kilder")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

const mapStateToProps = (state) => ({
  tenants: state.auth.tenants,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(SettingDropdown);
