import React from 'react'
import AthleteTopBar from './AthleteTopBar'
import Footer from './Footer'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const AthleteLayout = ({tenant, children}) => {

  return (
    <div style={{ marginTop: '20px' }}>
      <div id="layout-wrapper">
        <AthleteTopBar
          tenant={tenant}
        />
        <div className="main-content">
          <div className="page-content">{children}</div>
        </div>
        <Footer />
        <ToastContainer toastStyle={{ backgroundColor: "#2e3648", color: "white"}} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  tenant: state.auth.tenant
})

export default connect(mapStateToProps, {})(AthleteLayout)