
export const saveToLocalStorage = (key, payload) => {
  window.localStorage.setItem(key, payload)
}

export const fetchFromLocalStorage = (key) => {
  const data = window.localStorage.getItem(key)
  if (data && data !== 'undefined'){
    return JSON.parse(data)
  }
  return null
}

export const isExpired = (timestamp) => {
  const currentTime = new Date().getTime()
  const cacheTime = new Date(timestamp).getTime()
  
  return (currentTime - cacheTime) > 24*60*60*1000 ? true : false
}

export const usingCache = () => {
  return fetchFromLocalStorage('useCache') === true ? true : false;
}