import React from 'react'
import { Card, CardBody, Row, Col } from 'reactstrap'
import S3UploadCSV from './S3UploadCSV'

const AdminS3Upload = () => {
	return (
		<>
			<Row className="mb-4 has-overlay">
				<div className="overlay">
					<div className="overlay-content">
						<p>Work in progress</p>
					</div>
				</div>
				<Col sm={6} md={6}>
					<Card>
						<CardBody>
							<h5 className="card-title mb-4">Upload .csv to S3</h5>
							<S3UploadCSV />
						</CardBody>
					</Card>
				</Col>
				<Col sm={6} md={6}>
					<Card>
						<CardBody>
							<h5 className="card-title mb-4">Upload video to S3</h5>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default AdminS3Upload
