import store from '../store'
import { Globals } from '../Globals'
const SocialService = {
    facebookLoginLink() {
        const { access_token, tenant } = store.getState().auth
        return `${Globals.API_URL}/api/facebook/login?tenant=${tenant.id}&jwt=${access_token}`
    },
    facebookBusinessLoginLink() {
        const { access_token, tenant } = store.getState().auth
        return `${Globals.API_URL}/api/facebook/business-login?tenant=${tenant.id}&jwt=${access_token}`
    },
    googleLoginLink() {
        const { access_token, tenant } = store.getState().auth
        return `${Globals.API_URL}/api/google/login?tenant=${tenant.id}&jwt=${access_token}`
    },
    twitterLoginLink() {
        const { access_token, tenant } = store.getState().auth
        return `${Globals.API_URL}/twitter/login?tenant=${tenant.id}&jwt=${access_token}`
    }
}

export default SocialService
