import React, { Component } from 'react'
import Loader from '../components/Loader'
import logo from '../assets/images/Logo_Foocus_Pos.png'

import ApiService from '../services/ApiService'
import { Row, Col, Card, CardBody, Alert } from "reactstrap"

class ActivateAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
          loading: true,
          activated: false,
          user: null,
          hasActiveUser: null,
          password: null,
          password_confirmation: null,
          validPassword: false
        }
    }

    componentDidMount() {
      const { uuid } = this.props.match.params
      console.log(uuid)

      ApiService.getActivationUser(uuid)
      .then(result => {
        const payload = result && result.data

        this.setState({loading: false, user: payload.user, hasActiveUser: payload.hasActiveUser})
        console.log(payload)
      }).catch(error => {
        this.setState({loading: false})
      })
    }

    activateAccount = () => {
      console.log('activateAccount')
      const { password, password_confirmation } = this.state
      const { uuid } = this.props.match.params
      ApiService.activateUser(uuid, { password, password_confirmation })
      .then(result => {
        this.setState({activated: true})
      })
      .catch(error => {
        this.setState({activated: true})
      })
    }

    activateExistingAccount = () => {
      console.log('activateExistingAccount')
      const { uuid } = this.props.match.params
      ApiService.activateUser(uuid, {})
      .then(result => {
        this.setState({activated: true})
      })
      .catch(error => {
        this.setState({activated: true})
      })
    }

    handlePasswordChange = (e) => {
      this.setState({password: e.target.value}, this.validatePassword)
    }
    
    handleRepeatPasswordChange = (e) => {
      this.setState({password_confirmation: e.target.value}, this.validatePassword)
    }
    
    validatePassword = () => {
        const { password, password_confirmation } = this.state
        const lowerCaseLetters = /[a-z]/g;
        const upperCaseLetters = /[A-Z]/g;
        const numbers = /[0-9]/g;

        if (
          !password.match(lowerCaseLetters) ||
          !password.match(upperCaseLetters) ||
          !password.match(numbers) ||
          password.length < 8 ||
          password !== password_confirmation
        ) {
          this.setState({validPassword: false})
        } else {
          this.setState({validPassword: true})
        }
      }

    content = () => {
      const { activated, loading, user, validPassword, hasActiveUser, password, password_confirmation } = this.state
      console.log('hasActiveUser', hasActiveUser)

      if (loading) {
        return (<Loader />)
      }
      else if (!user) {
        return (<p>Aktiveringskoden eksisterer ikke i vårt system. Dette kan komme av at den er brukt tidligere eller at den er feilskrevet. Vennligst ta kontakt med oss på info@foocus.ai dersom dette er feil.</p>)
      }
      else if (activated) {
        return (
          <div>
            <p>Kontoen din er nå aktivert og du kan logge inn med valgt passord.</p>
            <p>Neste steg er å legge til datakildene dine.</p>
            <p>Klikk på knappen under for å logge inn og legge inn datakilder.</p>
            <a
              href="/onboarding"
              className="btn btn-primary btn-block waves-effect waves-light"
            >
              Legg til datakilder
            </a>

            <a
              href="https://app.foocus.ai"
              className="btn btn-primary btn-block waves-effect waves-light"
            >
              Hopp fram til dashbord
            </a>

          </div>
        )
      }
      else if (hasActiveUser) {
        return (
          <div>
            <p>Vi ser at e-postadressen din allerede er registrert i Foqus-ekosystemet.</p>
            <p>Du kan derfor aktivere kontoen din og logge deg på med samme brukernavn og passord som tidligere.</p>
            <div>
              <button onClick={this.activateExistingAccount} className="btn btn-primary w-md waves-effect waves-light" type="button">Aktiver konto</button>
            </div>
          </div>)
      } else {
        return (
          <div>
            <p>
              Aktiver kontoen din ved å velge et passord i skjemaet nedenfor.
            </p>
            <form
              className="form-horizontal mt-4"
            >
              {this.props.user && this.props.user ? (
                <Alert color="success">
                  Register User Successfully
                </Alert>
              ) : null}
              {this.props.registrationError &&
              this.props.registrationError ? (
                <Alert color="danger">
                  {this.props.registrationError}
                </Alert>
              ) : null}


              <div className="form-group">
                <label htmlFor="password">Passord</label>
                <input value={password ?? ''} onChange={this.handlePasswordChange} className="form-control" id="password" name="password" type="password" placeholder="Skriv inn ønsket passord" required />
              </div>
              <div className="form-group">
                <label htmlFor="password-repeat">Gjenta passord</label>
                <input value={password_confirmation ?? ''} onChange={this.handleRepeatPasswordChange} className="form-control" id="password-repeat" name="password-repeat" type="password" placeholder="Gjenta ønsket passord" required />
              </div>
              <p><small>Passordet må bestå av minst 8 tegn, med minst én stor bokstav, én liten og ett tall.</small></p>
              <Row className="form-group">
                <div className="col-12 text-right">
                  <button disabled={!validPassword} onClick={this.activateAccount} className="btn btn-primary w-md waves-effect waves-light" type="button">Aktiver konto</button>
                </div>
              </Row>
            </form>
          </div>
        )
      }
    }

    render() {
        return (
            <>
              <div className="account-pages my-5 pt-5">
                <div className="container">
                  <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                      <div className="position-relative">
                        <div className="text-center mb-5">
                            <img src={logo} height="50" alt="logo" />
                        </div>
                        <Card className="overflow-hidden">
                          <div className="bg-primary">
                            <div className="text-primary text-center p-4">
                              <h5 className="text-white font-size-20 m-0">
                                Aktiver konto
                              </h5>
                            </div>
                          </div>
                          <CardBody className="p-4">
                            <div className="p-3">
                              {this.content()}
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="mt-5 text-center">
                        <p>
                          © {new Date().getFullYear()} Foocus
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
        )
    }
}

export default ActivateAccount
