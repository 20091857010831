import React, { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane, Spinner, Table } from 'reactstrap'
import classnames from 'classnames'
import ApiService from '../services/ApiService'

const AccessTokenTable = () => {

    const [sources, setSources] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        const fetchAccessTokens = async () => {
            setLoaded(false);
            const res = await ApiService.getAccessTokens();
            const data = res.data && res.data.sources;
            setSources(data);
            setLoaded(true);
        }

        fetchAccessTokens();
    }, [])

    const toggleTab = (tab) => {
        if (activeTab !== tab){
            setActiveTab(tab);
        }
    }

    const renderTab = (source, index) => {
        return (
            <NavItem key={index}>
                <NavLink
                    href="#"
                    className={classnames({
                        active: activeTab === index
                    })}
                    onClick={() => {toggleTab(index)}}
                >
                    {source.source}
                </NavLink>
            </NavItem>
        )
    }

    const getExpiresDates = (expires_at) => {
        const now = new Date().getTime() / 1000;
        const diffTime = expires_at - now;
        const diffDays = Math.ceil(diffTime / (60 * 60 * 24));
        return diffDays;
    }

    const renderSource = (source, index) => {
        return (
            <TabPane tabId={index} className="p-3" key={index}>
                <div style={{ height:'400px', overflowY:'scroll' }}>
                <Table striped borderless size="sm">
                    <thead>
                        <tr style={{ textAlign:'center' }}>
                            <th style={{ color: '#9d9d9d' }}>
                                ID
                            </th>
                            <th style={{ color: '#9d9d9d' }}>
                                Tenant
                            </th>
                            <th style={{ color: '#9d9d9d' }}>
                                Page
                            </th>
                            <th style={{ color: '#9d9d9d' }}>
                                Expires
                            </th>
                            <th style={{ color: '#9d9d9d' }}>
                                Last updated
                            </th>
                            <th style={{ color: '#9d9d9d' }}>
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {source.data.map((entry, index) => (
                        <tr key={`token-${index}-${entry.page}`} style={{ textAlign:'center' }}>
                                <td>{entry.id}</td>
                                <td>{entry.name}</td>
                                <td>{entry.page}</td>
                                <td>{getExpiresDates(entry.expires_at)} dager</td>
                                <td>{entry.updated_at.split(' ')[0]}</td>
                                <td style={{ fontSize:'20px', color: entry.status === 'active' ? 'green' : 'red' }}>{ entry.status === 'active' ? <i className='mdi mdi-checkbox-marked-circle'></i> : <i className='mdi mdi-alert-circle'></i>}</td>
                            </tr>
                        ))}
                    </tbody>
				</Table>
                </div>
            </TabPane>
        )
    }

    return (
        <>
                <Nav pills className="navtab-bg my-4 center-pills">
                    {sources.map((source, index) => renderTab(source, index))}
                </Nav>
                    {loaded ? <TabContent activeTab={activeTab}>
                            {sources.map((source, index) => renderSource(source, index))}
                        </TabContent> 
                        : <>
                            <Spinner color="primary" />
                            </>
                    }       
            </>
  )
}

export default AccessTokenTable