import React from "react";
import { Col, Row, Spinner } from "reactstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ApiService from "../services/ApiService";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";

const SoldTickets = ({ tenant }) => {
  const { t } = useTranslation();

  const { data, isLoading, isRefetching, isSuccess } = useQuery({
    queryKey: ["ticketsSoldMatch", tenant.id],
    queryFn: ApiService.getTicketsNextMatch,
    select: (res) => res.data,
    placeholderData: {
      data: {
        capacity: 0,
        ticketsSoldNextMatch: 0,
        seasonTickets: 0,
        ticketsSoldPreviousMatch: 0,
        seasonTicketsSoldPreviousMatch: 0,
      },
    },
  });

  const chartOptions = () => {
    const availableTickets = Math.max(
      data.capacity - data.ticketsSoldNextMatch,
      1
    );
    const ticketsSoldNextMatch = data.ticketsSoldNextMatch;
    const percentSold = Math.round((ticketsSoldNextMatch / data.capacity) * 100) || 0;

    return {
      credits: { enabled: false },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: "180px",
      },
      title: {
        text: `${percentSold}%`,
        align: "center",
        verticalAlign: "middle",
        y: 50,
        style: {
          fontWeight: "bold",
          color: "white",
        },
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
            distance: -50,
            style: {
              fontWeight: "bold",
              color: "white",
            },
          },
          startAngle: -90,
          endAngle: 90,
          center: ["50%", "100%"],
          size: "150%",
        },
      },
      series: [
        {
          type: "pie",
          name: "Andel",
          innerSize: "60%",
          colors: ['#EBC040', '#117CA1', '#e3e6e4'],
          data: [
            // ["Sesongbilletter", seasonTickets],
            // ["Enkeltbilletter", data.ticketsSoldNextMatch - data.seasonTickets],
            ["Solgte billetter", data.ticketsSoldNextMatch],
            ["Tilgjengelig", availableTickets],
          ],
        },
      ],
    };
  };

  return (
    <>
      {isSuccess && (
        <HighchartsReact highcharts={Highcharts} options={chartOptions()} />
      )}

      <h6 className="text-center text-uppercase mb-3">
        {t("Billettsalg ift kapasitet")}
        {(isLoading || isRefetching) && (
          <Spinner
            color="primary"
            size="sm"
            style={{ position: "absolute", right: 20 }}
          />
        )}
      </h6>

      {isSuccess && (
        <>
          <Row>
            <Col sm={8} className="text-warning text-left">- {t("Salg sist oppgjør")}</Col>
            <Col sm={4} className="text-warning text-right">{data.ticketsSoldPreviousMatch}</Col>
        </Row>
        <Row>
            <Col sm={8} className="text-warning text-left">- {t("Kapasitet")}</Col>
            <Col sm={4} className="text-warning text-right">{data.capacity}</Col>
        </Row>
        <Row>
            <Col sm={8} className="text-warning text-left">- {t("Salg til nå dette oppgjør")}</Col>
            <Col sm={4} className="text-warning text-right">{data.ticketsSoldNextMatch}</Col>
        </Row>
        {/* <Row>
            <Col sm={8} className="text-warning text-left">- {t("Antall sesongkort")}</Col>
            <Col sm={4} className="text-warning text-right">{data.seasonTickets}</Col>
        </Row>
        <Row>
            <Col sm={8} className="text-warning text-left">- {t("Antall enkeltbilletter")}</Col>
            <Col sm={4} className="text-warning text-right">{data.ticketsSoldNextMatch - data.seasonTickets}</Col>
        </Row> */}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(SoldTickets);