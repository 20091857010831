import React from 'react';
import { Spinner } from 'reactstrap';

const CardSpinner = ({loading}) => {
	if (loading === false) {
		return <></>;
	}
	return (
		<div className="card-spinner">
			<Spinner size="48" color="white" />
		</div>
	);
};

export default CardSpinner;
