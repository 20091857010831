import React from "react";
import useDarkMode from "use-dark-mode";
import { useTheme } from "../utils/useTheme";
import moon from'../assets/moon-icon.png';

const ThemeBtn = () => {
  const darkMode = useDarkMode(true);
  const theme = useTheme();

  function refreshPage() {
    window.location.reload(false);
    darkMode.toggle()
  }

  if (theme === "dark-mode") {
    return <button className="bg-transparent border-0 ti-shine" onClick={refreshPage} />;
  } else {
    return (
      <img src={moon} alt="" className="moon" type="button" onClick={refreshPage} />
    );
  }
};
export default ThemeBtn;