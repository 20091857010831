import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import ApiService from '../services/ApiService';

/* Components */
import PartnereDummy from './PartnereDummy';
import PartnereLive from './PartnereLive';
import TagManager from 'react-gtm-module';

TagManager.dataLayer({
  dataLayer: {
    event: 'pageview',
    pagePath: '/partnere',
    pageTitle: 'Partnere',
  },
});

const Partnere = ({ tenant }) => {
  const [isActivePartner, setIsActivePartner] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchIsSponsor = async () => {
      setIsLoading(true);
      const res = await ApiService.getPartnerActive()
      setIsActivePartner(res.data && res.data.active);
      setIsLoading(false);
    };

    fetchIsSponsor();
  }, [tenant])

  if (isLoading) {
    return <div className="container-fluid pt-4"><Spinner></Spinner></div>;
  }

  const template = isActivePartner ? 'live' : 'dummy';

  return (
    <>
      {
        {
          'dummy': <PartnereDummy />,
          'live': <PartnereLive />
        }[template]
      }
    </>
  );
}

const mapStateToProps = state => ({
  tenant: state.auth.tenant
});

export default connect(mapStateToProps)(Partnere);