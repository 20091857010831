import React from 'react'
import {Form, FormGroup, Input, Label, Button} from 'reactstrap'

const S3UploadCSV = () => {
  return (
    <Form>
      <Label>Upload CSV</Label>
      <FormGroup>
          <Input type="filename" name="name" placeholder="Filename" />
      </FormGroup>
      <FormGroup>
          <Input type="file" name="file" placeholder="File" />
      </FormGroup>
      <Button>Upload</Button>
  </Form>
  )
}

export default S3UploadCSV