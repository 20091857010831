import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import AdminFacebookJobs from './AdminFacebookJobs'
import AdminInstagramJobs from './AdminInstagramJobs'
import AdminOverallMetricsJobs from './AdminOverallMetricsJobs'


const AdminJobsPanel = () => {
  return (
    <>
            <h4 className="card-title mb-4">Jobs</h4>
            <Row className='mb-4'>
                <Col sm={6} md={4}>
                    <Card className="mini-stat bg-card text-white">
                        <CardBody>
                            <h5 className="card-title mb-4">Facebook</h5>
                            <AdminFacebookJobs />
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={6} md={4}>
                    <Card className="mini-stat bg-card text-white">
                        <CardBody>
                            <h5 className="card-title mb-4">Instagram</h5>
                            <AdminInstagramJobs />
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={6} md={4}>
                    <Card>
                        <CardBody>
                            <h5 className="card-title mb-4">OverallMetrics</h5>
                            <AdminOverallMetricsJobs />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
    </>
  )
}

export default AdminJobsPanel