import { Card, CardBody, Spinner } from "reactstrap";

const ValueSponsorMetric = ({sponsor, metric, logo, value, growth, loading}) => {

  return (
    <Card>
      <CardBody>
        <div style={{display: 'flex', justifyContent: 'space-between', gap: 16}}>
          <div style={{display: 'flex', gap: 16, alignItems: 'center' }}>
            { loading ? <Spinner color="primary" /> : 
              <img src={logo} alt={sponsor} style={{ width: '60px', maxHeight: '46px', objectFit: 'contain' }} />
            }
            <div>
              <p
                style={{textTransform: 'capitalize'}}
                className="font-size-16 m-0 font-weight-medium font-color-white"
              >{ sponsor !== undefined && sponsor}</p>
              <p className="font-size-12 m-0">{metric}</p>
            </div>
          </div>
          <div style={{textAlign: 'right'}}>
            { loading ? <Spinner color="primary" /> : 
              <>
                <p className="font-size-16 m-0 font-weight-bold font-color-white">{value}</p>
                <p className="font-size-12 m-0 font-weight-bold text-warning">{Math.round(growth * 100)}%</p>
              </>
            }
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default ValueSponsorMetric;
