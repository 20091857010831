import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { useTranslation } from "react-i18next";
import DemographicsChart from "../components/DemographicsChart";
import Geography from "../components/Geography";

const PersonAudience = () => {
    const { t } = useTranslation();

    return (
        <>
            <Row className="mb-4">
                <Col sm={12} md={6}>
                    <Card className="mini-stat bg-card text-white">
                    <CardBody>
                        <h4 className="card-title mb-4">
                        {t('Alder og kjønn')}
                        </h4>
                        <DemographicsChart />
                    </CardBody>
                </Card>
                </Col>
                <Col sm={12} md={6}>
                <Card className="mini-stat bg-card text-white divider">
                    <CardBody>
                        <h4 className="card-title mb-4">
                        {t('Geografi')}
                        </h4>
                        <Geography 
                        google={false}
                        />
                    </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

const mapStateToProps = state => ({
  tenant: state.auth.tenant
})

export default connect(mapStateToProps, {})(PersonAudience)
