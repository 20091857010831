import React, { Component } from 'react'
import { Card, CardBody } from 'reactstrap'
import classNames from 'classnames'
class DummyMetric extends Component {
	render() {
        const { title, trending, value, percent } = this.props
        const trendingUp = (trending === 'up')
		return (
			<>
				<Card className="mini-stat bg-card text-white h-100">
                    <CardBody>
                        <div>
                            <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                { title }
                            </h5>
                            <h4 className="font-weight-medium font-size-24">
                                { value }{" "}
                                <i className={classNames({
                                    'mdi': true,
                                    'ml-2': true,
                                    'mr-1': true,
                                    'mdi-arrow-down': !trendingUp,
                                    'text-danger': !trendingUp,
                                    'mdi-arrow-up': trendingUp,
                                    'text-success': trendingUp,
                                })}></i>
                                <span className={classNames({
                                    'font-size-16': true,
                                    'text-danger': !trendingUp,
                                    'text-success': trendingUp
                                })}>{ percent }</span>
                            </h4>
                        </div>
                    </CardBody>
                </Card>
			</>
		)
	}
}

export default DummyMetric