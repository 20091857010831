import { SET_CURRENCIES, SET_SELECTED_CURRENCY } from '../actions/types';
import currencyCountries from '../../utils/currencyCountries.json';

const initialState = {
  currencies: [
    {
      currency: 'NOK',
      country: 'NO',
      value: 1,
    },
  ],
  selectedCurrency: {
    currency: 'NOK',
    country: 'NO',
    value: 1,
  },
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_CURRENCY:
      return {
        ...state,
        selectedCurrency: action.payload,
      }
      case SET_CURRENCIES:
        return {
          ...state,
          currencies: currencyCountries.currencies.map(currencyCountry => ({
            ...currencyCountry,
            value: "NOK",
            // value: action.payload[currencyCountry.currency],
          })),
      }
    default:
	    return state
  }
};

export default currencyReducer;
