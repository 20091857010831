import React from "react";
import { Row, Card, CardBody, Col } from "reactstrap";
import { connect } from "react-redux";

import BoxedMetric from "../components/BoxedMetric";

import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighMaps from "highcharts/modules/map";

import DemographicsChart from "../components/DemographicsChart.js";
import Geography from "../components/Geography.jsx";
import { useTranslation } from "react-i18next";
import InfoTooltip from "../components/InfoTooltip.js";
import numeral from "numeral";

HighchartsMore(Highcharts);
HighMaps(Highcharts);

const AthleteAudience = ({ selectedCurrency, tenant }) => {
  const {t} = useTranslation();

  return (
    <div className="container-fluid pt-4 p-0 value-person-view">
      <div>
        <h5>{t('Social Metrics Overview')}</h5>
      </div>
      <div>
        <div className="flex" style={{ gap: 12, marginBottom: "1.5rem" }}>
          <BoxedMetric
            titleFn={() => t('Followers')}
            metric="total-followers"
            subtitle="followers"
            colour="violet"
            primaryFn={primary => `${numeral(primary).format('0.0a')} ${t('Followers')}`}
          />
          <BoxedMetric
            titleFn={() => t('Growth')}
            metric="total-followers-growth"
            colour="blue"
            primaryFn={primary => `${primary} ${t('last 3 months')}`}
          />
        </div>
        <Row className="mb-4">
          <Col md={12}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Alder og kjønn")}
                  <InfoTooltip
                    tooltip={t(
                      "Viser demografisk nedbrytning av følgere i forhold til alder/livsfase og kjønn."
                    )}
                  />
                </h4>
                <DemographicsChart />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={12}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t('Geografi')}
                  <InfoTooltip tooltip={t('Viser kommunene der følgere bor. Dette er ikke komplett data, mange brukere har ikke lokasjonsdata tilgjengelig.')} />
                </h4>
                <Geography />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  selectedCurrency: state.currency.selectedCurrency,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(AthleteAudience);
