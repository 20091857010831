import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { ALL_BRANDS, DATA_SOURCES } from "../keys";
import { toast } from "react-toastify";

const useEnableSponsors = () => {
  const tenant = useSelector((state) => state.auth.tenant);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [DATA_SOURCES, tenant.id],
    mutationFn: ApiService.setSponsors,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [ALL_BRANDS, tenant.id] });
    },
    onError: (error) => {
      console.log(error);
      toast.error("Failed to enable sponsors");
    },
    onSettled: () => {
      toast.success("Sponsors updated");
      queryClient.invalidateQueries({ queryKey: [ALL_BRANDS, tenant.id] })
    },
  });
};

export default useEnableSponsors;
