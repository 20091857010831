import React, { Component } from 'react'

class SettingsSponsorObject extends Component {
  render() {
    return (
      <>
        <div className="container-fluid">
        
        </div>
      </>
    );
  }
}

export default SettingsSponsorObject