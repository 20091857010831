import React from 'react';
import { useHistory } from 'react-router-dom';

function RegistrationPage() {
  const history = useHistory();

  const handleRedirect = (path) => {
    history.push(path);
  };

  return (
    <div>
      <h2>Select your role</h2>
      <button onClick={() => handleRedirect('/PersonRegistration')}>Individual</button>
      <button onClick={() => handleRedirect('/organization')}>Organization</button>
      <button onClick={() => handleRedirect('/sponsor')}>Sponsor</button>
    </div>
  );
}

export default RegistrationPage;
