import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { useSponsorLogoStats } from "../query/hooks";
import { useTheme } from "../utils/useTheme";

const SponsorLogoStatsChart = ({ date, sponsorObjectId }) => {
  const [activeTab, setActiveTab] = useState(0);
  var darkTheme = true;
  // check if light or dark mode
  if (useTheme() === "light-mode") {
    darkTheme = false;
  }

  const {
    data: graphs,
    isLoading,
    isRefetching,
    isSuccess,
  } = useSponsorLogoStats(sponsorObjectId, date.startDate, date.endDate);

  const chartOptions = (graph) => {
    var labelcolor = "#000";
    if (darkTheme === true) {
      labelcolor = "#fff";
    }

    return {
      credits: { enabled: false },
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: graph.data.yTitle,
        },
        labels: {
          style: {
            color: labelcolor,
          },
        },
      },
      xAxis: {
        categories: graph.data.labels,
        labels: {
          rotation: -45,
          style: {
            color: labelcolor,
          },
        },
        title: {
          text: graph.data.xTitle,
        },
      },
      series: graph.data.series,
      plotOptions: {
        column: {
          stacking: "normal",
          borderColor: null,  
        },
      },
      
    };
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const renderTab = (graph, index) => {
    return (
      <NavItem key={index}>
        <NavLink
          href="#"
          className={classnames({
            active: activeTab === index,
          })}
          onClick={() => {
            toggleTab(index);
          }}
        >
          {graph.source}
        </NavLink>
      </NavItem>
    );
  };

  const renderSource = (graph, index) => {
    return (
      <TabPane tabId={index} className="p-3" key={index}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions(graph)}
        />
      </TabPane>
    );
  };

  return (
    <>
      {(isLoading || isRefetching) && <Spinner color="primary" />}
      {isSuccess && (
        <>
          <Nav pills className="navtab-bg my-4 center-pills">
            {graphs.map((graph, index) => renderTab(graph, index))}
          </Nav>
          <TabContent activeTab={activeTab}>
            {graphs.map((graph, index) => renderSource(graph, index))}
          </TabContent>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
});

export default connect(mapStateToProps)(SponsorLogoStatsChart);
