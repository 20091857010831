import React from "react";
import Highcharts from 'highcharts/highstock';
import { Spinner } from "reactstrap";
import HighchartsReact from "highcharts-react-official";
import ApiService from "../services/ApiService";
import { connect } from "react-redux";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "../utils/useTheme";

const TvViewers = ({ date, tenant }) => {
  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }
  const { data, isLoading, isRefreshing, isSuccess } = useQuery({
    queryKey: ["tvViewers", tenant.id, startDate, endDate],
    queryFn: () => ApiService.getTvViewers(since, until),
    select: (data) => data.data,
    enabled,
    placeholderData: {
      data: {
        labels: [],
        series: [],
        xTitle: "",
        yTitle: "",
      },
    },
  });

  var darkTheme = true
  // check if light or dark mode
  if (useTheme() === "light-mode"){
    darkTheme = false
  }

  const chartOptions = () => {
    var backgroundColor = "#fff"
    var labelcolor = "#000"
    if (darkTheme === true) {
      backgroundColor = "#2B283B"
      labelcolor = "#fff"
    } 
    return {
      credits: { enabled: false },
      chart: {
        type: "column",
        backgroundColor: backgroundColor,  // <-- Add background color
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: data.yTitle,
        },
        labels: {
          style: {
            color: labelcolor
         }
        }
      },
      xAxis: {
        categories: data.labels,
        labels: {
          rotation: -45,
          style: {
            color: labelcolor
         }
        },
        title: {
          text: data.xTitle,
        },
        min: 0,
        max: 10,
        scrollbar: {
          enabled: true,
        },
      },
      series: data.series,
      plotOptions: {
        column: {
          stacking: "normal",
          borderColor: null, 
         },
      },
      scrollbar: {
        liveRedraw: false
      },

    };
  };

  return (
    <>
      {isLoading && isRefreshing && <Spinner color="primary" />}
      {isSuccess && (
        <HighchartsReact highcharts={Highcharts} options={chartOptions()} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(TvViewers);
