import React, { useEffect, useState } from 'react'
import TopBar from './TopBar'
import NavbarPerson from './NavbarPerson'
import Footer from './Footer'
import { connect } from 'react-redux'

const PersonLayout = ({toggleRightSidebar, topbarTheme, tenant, children}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <div style={{ marginTop: '20px' }}>
      <div id="layout-wrapper">
        <TopBar
          theme={topbarTheme}
          isMenuOpened={isMenuOpened}
          toggleRightSidebar={toggleRightSidebar}
          openLeftMenuCallBack={openMenu}
          tenant={tenant}
        />
        <NavbarPerson /> 

        {
        }
        <div className="main-content">
          <div className="page-content">{children}</div>
        </div>
        
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  tenant: state.auth.tenant
})

export default connect(mapStateToProps, {})(PersonLayout)