import React, { Component } from 'react'
import { connect } from 'react-redux'

/* Components */
import DashboardEliteserien from './DashboardEliteserien'
import DashboardToppserien from './DashboardToppserien'
import DashboardLiga from './DashboardLiga'
import DashboardSponsor from './DashboardSponsor'
import DashboardPerson from './DashboardPerson'

class Dashboard extends Component {
  render() {
    const { tenant } = this.props
    return (
      <>
        {
          {
            'eliteserien': <DashboardEliteserien />,
            'league': <DashboardLiga />,
            'obosligaen': <DashboardEliteserien />,
            'toppserien': <DashboardToppserien />,
            'sponsor': <DashboardSponsor />,
            'person': <DashboardPerson />
          }[tenant.template]
        }
      </>
    );
  }
}

const mapStateToProps = state => ({
  tenant: state.auth.tenant
})

export default connect(mapStateToProps, {})(Dashboard)