import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Alert } from 'reactstrap'

/* Components */

import DummyValuePerPartnerTable from '../components/DummyValuePerPartnerTable'
import DummyValueLogoExposes from '../components/DummyValueLogoExposes'

import DummyMetric from '../components/DummyMetric'
import DummyStackedChart from '../components/DummyStackedChart'
import partnerdata from '../data/partnerdata.json'

class PartnereDummy extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <>
        <div className="container-fluid pt-4 has-overlay">
          <Row className="mb-4">
            <Col xl={3} md={6} className="mb-4 mb-xl-0">
              <DummyMetric value="23 mill" title="TOTAL SPONSORVERDI (HIA)" trending="up" percent="+25%" />
            </Col>
            <Col xl={3} md={6} className="mb-4 mb-xl-0">
              <DummyMetric value="55.341" title="EKSPONERINGER (HIA)" trending="down" percent="-6%" />
            </Col>
            <Col xl={3} md={6}>
              <DummyMetric value="123.000" title="VISNINGER (HIA)" trending="up" percent="+4%" />
            </Col>
            <Col xl={3} md={6}>
              <DummyMetric value="464" title="EKSPONERINGSTID (MINUTTER - HIA)" trending="up" percent="+3%" />
            </Col>
          </Row>
        
          <Row className="mb-4">
            <Col sm={12}>
            <Card className="mini-stat bg-card text-white">
                <CardBody>
                <h4 className="card-title mb-4">Sponsorverdi per partner</h4>
                <DummyStackedChart data={partnerdata} />
              </CardBody>
              </Card>
              <Alert
                    className="mt-3 bg-card"
                      color="light"
                      isOpen={true}
                      toggle={() => {}}
                    >
                      I oktober har klubben generert en estimert medieverdi 2,8 millioner kroner. Det er Sparebank1 og Adidas som oppnår høyest verdi for sine plasseringer. Klubben genererer nesten 900.000 kroner i verdi for Sparebank1 og 700.000 kroner for Adidas i oktober. Noe nedgang siden September, men dette er mye på grunn av lavere seertall på kampene i oktober, i tillegg til landslagspause som gir færre kamper.
                </Alert>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
                <CardBody>
                    <h4 className="card-title mb-4">VERDI PER PARTNER HITTIL I ÅR</h4>
                    <DummyValuePerPartnerTable />
              </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
                <CardBody>
                    <h4 className="card-title mb-4">SNITTVERDI VARELAGER</h4>
                    <DummyValueLogoExposes />
              </CardBody>
              </Card>
            </Col>
          </Row>

          <div className="overlay">
            <div className="overlay-content">
              <p>Sponsor-rapportering er ikke aktivert for denne kontoen. Ønsker du å måle logoeksponering for rapportering til noen av dine partnere, send en e-post til oss på <a href="mailto:kim@foocus.ai">kim@foocus.ai</a> hvor du oppgir hvilke partnere det er aktuelt å måle så kommer vi tilbake til deg med et tilbudsforslag.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default PartnereDummy