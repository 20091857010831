import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { PARTNER_VALUE_PER_SOURCE } from "../keys";

const usePartnerValuePerSource = (partnerId, selectedTenantId = "") => {
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);

  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }
  return useQuery({
    queryKey: [PARTNER_VALUE_PER_SOURCE, tenant.id, partnerId, selectedTenantId, startDate, endDate],
    queryFn: () => ApiService.getPartnerValuePerSource(partnerId, selectedTenantId, since, until),
    select: (data) => data.data.sources,
    enabled,
    placeholderData: { data: { sources: [] } },
  });
};
export default usePartnerValuePerSource;
