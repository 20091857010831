import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { TENANTS, MERGED } from "../keys";

const useMergedTenants = () => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [TENANTS, MERGED, tenant.id],
    queryFn: () => ApiService.getMergedTenants(),
    select: (data) => data.data,
    placeholderData: {data: []},
  });
};

export default useMergedTenants;
