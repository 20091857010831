import React, { useMemo } from "react";
import ArrowScroll from "../components/ArrowScroll";
import { useLocation } from 'react-router-dom';
import LineGraphAthlete from "../components/LineGraphAthlete";
import useAthleteMetric from "../query/hooks/useAthleteMetric";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row } from "reactstrap";

const PerformanceMedia = () => {
  const location = useLocation();
  const history = useHistory();
  const search = useMemo(() => new URLSearchParams(location.search), [location]);
  const source = search.get('source') || 'instagram';

  const options = ['instagram', 'x', 'facebook', 'youtube'];

  const onOptionChange = (option) => {
    history.replace({
      search: `?source=${option}`
    });
  }

  return (
    <div className="container-fluid pt-4 p-0 performance-person-view">
      <ArrowScroll selectedValue={source} options={options} onOptionChange={onOptionChange} />
      <Row className="mb-4">
        <Col xs={12}>
          <LineGraphAthlete
            title="Reach"
            subtitle="Impressions"
            useGraphQuery={useAthleteMetric}
            queryParams={[`${source}-reach`]}
            defaultActiveTab={1} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <LineGraphAthlete
            title="Engagement"
            subtitle="Impressions"
            useGraphQuery={useAthleteMetric}
            queryParams={[`${source}-engagement`]}
            defaultActiveTab={1}
          />
        </Col>
      </Row>
    </div>
  );
}

export default PerformanceMedia;
