import React from "react";
import { Collapse } from "reactstrap";
import { NavLink } from "react-router-dom";
import DatePicker from "../components/DatePicker";
import { connect } from "react-redux";
import { setDate } from "../store/actions/dateActions";
import { saveToLocalStorage } from '../utils/handleLocalStorage';
import { useEffect, useState } from 'react';
import { Globals } from '../Globals'
import PartnerLink from "../components/PartnerLink";

const NavbarFoqus = ({ menuOpen, date, setDate }) => {
  const {startDate, endDate} = date;
  const [useCache, setUseCache] = useState(true)

  // Set initial state
  useEffect(()=>{
    if(Globals.caching === true) {
      saveToLocalStorage('useCache', true);
      setUseCache(true);
    }else{
      saveToLocalStorage('useCache', false);
      setUseCache(false);
    }
  },[])

  const refreshPage = () => {
    window.location.reload(false);
  }

  const toggle = () => {
    saveToLocalStorage('useCache', !useCache)
    setUseCache(!useCache)
    refreshPage();
    // const currentValue = fetchFromLocalStorage('useCache') === 'true' ? true : false;
    // toggleCache(!usingCache)
  }

  const purgeCache = () => {

    window.localStorage.clear()

    saveToLocalStorage('useCache', useCache)
  }

  const onChange = (dates) => {
    const [start, end] = dates;
    setDate({
      startDate: new Date(
        new Date(start).getFullYear(),
        new Date(start).getMonth(),
        1
      ),
      endDate: end
        ? new Date(new Date(end).getFullYear(), new Date(end).getMonth() + 1, 0)
        : undefined,
    });
  };
  return (
    <>
      <div className="topnav">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
            <Collapse isOpen={menuOpen} className="navbar-collapse">
              <div id="navigation">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin">
                      <i className="ti-crown mr-2"></i>Admin
                    </NavLink>
                  </li>
                  <PartnerLink />
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/brand">
                      <i className="ti-heart mr-2"></i>Merkevare
                    </NavLink>
                  </li>
                </ul>
              </div>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                showMonthYearPicker
                dateFormat="MMM yy"
                confirm
                selectsRange
              />
              <button onClick={purgeCache}><i className="ti-trash" /></button>
              <label>Cache</label>
              <input type='checkbox' checked={useCache} onChange={toggle}/>
            </Collapse>
          </nav>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
});

export default connect(mapStateToProps, { setDate })(NavbarFoqus);
