import React from 'react';
import { Collapse } from 'reactstrap';
import { NavLink} from 'react-router-dom';
import DatePicker from '../components/DatePicker';
import { connect } from 'react-redux';
import { setDate } from '../store/actions/dateActions'
import { saveToLocalStorage } from '../utils/handleLocalStorage';
import { useEffect, useState } from 'react';
import { Globals } from '../Globals'
import { useTranslation } from 'react-i18next'
import PartnerLink from '../components/PartnerLink';



const NavbarEliteserien = ({menuOpen, date, setDate}) => {
  const {startDate, endDate} = date;
  const [, setUseCache] = useState(true)
  const {t} = useTranslation();

  // Set initial state
  useEffect(()=>{
    if(Globals.caching === true) {
      saveToLocalStorage('useCache', true);
      setUseCache(true);
    }else{
      saveToLocalStorage('useCache', false);
      setUseCache(false);
    }
  },[])

  const onChange = (dates) => {
    const [start, end] = dates;
    setDate({
      startDate: new Date(new Date(start).getFullYear(), new Date(start).getMonth(), 1),
      endDate: end ? new Date(new Date(end).getFullYear(), new Date(end).getMonth() + 1, 0) : undefined,
    });
  };



  return (
    <>
      <div className="topnav">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
            <Collapse
              isOpen={menuOpen}
              className="navbar-collapse"
            >
              <div id="navigation">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/dashboard" >
                      <i className="ti-pulse mr-2"></i>{t('Oversikt')}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/fans">
                      <i className="ti-user mr-2"></i>{t('Fans')}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/media">
                      <i className="ti-headphone-alt mr-2"></i>{t('Media')}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/arena">
                      <i className="ti-home mr-2"></i>{t('Arena')}
                    </NavLink>
                  </li>
                  <PartnerLink />
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/reports">
                      <i className="ti-clipboard mr-2"></i>{t('Rapport')}
                    </NavLink>
                  </li>
                </ul>
              </div>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                showMonthYearPicker
                dateFormat="MMM yy"
                selectsRange
                confirm
              />

            </Collapse>
          </nav>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  date: state.date
});

export default connect(mapStateToProps, {setDate})(NavbarEliteserien);
