import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

const ArrowScroll = ({ selectedValue, options, onOptionChange }) => {
  const [currentIndex, setCurrentIndex] = useState(options.indexOf(selectedValue));

  const handleLeftClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      onOptionChange(options[currentIndex - 1]);
    }
  };

  const handleRightClick = () => {
    if (currentIndex < options.length - 1) {
      setCurrentIndex(currentIndex + 1);
      onOptionChange(options[currentIndex + 1]);
    }
  };

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const handlers = useSwipeable({
    onSwipedLeft: () => handleRightClick(),
    onSwipedRight: () => handleLeftClick(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div {...handlers} style={{ display: 'flex', justifyContent: 'center'}}>
      {currentIndex > 0 && <button style={{ backgroundColor: 'transparent', color: 'white', border: 0}} onClick={handleLeftClick}>&lt;</button>}
      <span style={{ fontSize: 18, fontWeight: 'bold' }}>{capitalize(options[currentIndex])}</span>
      {currentIndex < options.length - 1 && <button style={{ backgroundColor: 'transparent', color: 'white', border: 0}} onClick={handleRightClick}>&gt;</button>}
    </div>
  );
};

export default ArrowScroll;