import React, { Component } from "react"
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
export default class DummyStackedChart extends Component {

    constructor(props){
        super(props)
        const { data } = this.props
        console.log(data)
        this.state = {
            alert: true,
            chartOptions: {
          credits: { enabled: false },
      chart: {
                    type: 'column'
                },
                title: {
                  text: ''
                },
                yAxis: {
                    title: {
                        text: data.yTitle
                    }
                },
                xAxis: {
                    categories: data.labels,
                    labels: {
                        rotation: -45
                    },
                    title: {
                        text: data.xTitle
                    }
                },
                series: data.series,
                plotOptions: {
                    column: {
                        stacking: 'normal'
                    }
                }
            },
            activeTab: '1'
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    navItems() {
        return (
            <Nav pills className="navtab-bg my-4 center-pills">
                <NavItem>
                    <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1"
                        })}
                        onClick={() => {
                          this.toggleTab("1")
                        }}
                    >
                        MÅNED
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2"
                        })}
                        onClick={() => {
                          this.toggleTab("2")
                        }}
                    >
                        AKKUMULERT
                    </NavLink>
                </NavItem>
            </Nav>
        )
    }
    render() {
        const { chartOptions  } = this.state
        const { showNav  } = this.props
        console.log(chartOptions)
        return (
            <>
                {showNav ? 
                (
                <Nav pills className="navtab-bg my-4 center-pills">
                    <NavItem>
                        <NavLink
                            className={classnames({
                            active: this.state.activeTab === "1"
                            })}
                            onClick={() => {
                            this.toggleTab("1")
                            }}
                        >
                            MÅNED
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({
                            active: this.state.activeTab === "2"
                            })}
                            onClick={() => {
                            this.toggleTab("2")
                            }}
                        >
                            AKKUMULERT
                        </NavLink>
                    </NavItem>
                </Nav>
                ):(<span></span>)}
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1" className="p-3">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                        />
                    </TabPane>
                    <TabPane tabId="2" className="p-3">
                    
                    </TabPane>

                </TabContent>
            </>
        )
    }
}
