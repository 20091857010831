import React, { useState } from 'react';
import { Card, CardBody, Row, Col, FormGroup, Button, Spinner } from 'reactstrap';
import ApiService from '../services/ApiService';

const AdminTVUpload = () => {
  const [TVFile, setTVFile] = useState(null);
  const [loading, setLoading] = useState({
    importTV: false,
    generateTVMetrics: false,
  });

  const onTVFileChange = (event) => {
    setTVFile(event.target.files[0]);
  };

  const importTV = async () => {
    if (!TVFile) {
      window.alert('Please select a file to import.');
      return;
    }

    setLoading({ ...loading, importTV: true });

    try {
      const formData = new FormData();
      formData.append('tv_excel', TVFile);
      await ApiService.importTV(formData);
      window.alert('Success');
    } catch (err) {
      const errorMessage = err?.response?.data?.error ?? 'An error occurred while importing TV data.';
      window.alert(errorMessage);
    }

    setLoading({ ...loading, importTV: false });
  };

  const generateTVMetrics = async () => {
    setLoading({ ...loading, generateTVMetrics: true });

    try {
      await ApiService.updateTVMetrics();
    } catch (err) {
      window.alert('An error occurred while generating TV metrics.');
    }

    setLoading({ ...loading, generateTVMetrics: false });
  };

  return (
    <Row className="mb-4 has-overlay">
      <Col sm={6} md={6}>
        <Card>
          <CardBody>
            <h5 className="card-title mb-4">Import TV Excel</h5>
            <FormGroup>
              <label htmlFor="tv-file-upload" className="custom-tv-file-upload">
                <i className="ti-file mr-2"></i>
                {TVFile ? TVFile.name : 'Select file'}
              </label>
              <input
                onChange={onTVFileChange}
                id="tv-file-upload"
                type="file"
                accept=".xlsx"
              />
              {TVFile && (
                <Button
                  size="sm"
                  className="ml-4"
                  color="transparent"
                  onClick={() => setTVFile(null)}
                >
                  <i className="ti-close" />
                </Button>
              )}
            </FormGroup>
            <Button
              onClick={importTV}
              disabled={loading.importTV || !TVFile}
              color="primary"
            >
              Import TV{' '}
              {loading.importTV && <Spinner color="white" size="sm" />}
            </Button>
          </CardBody>
        </Card>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <h5 className="card-title mb-4">TV Jobs</h5>
            <p>Generate TV metrics on the last two months</p>
            <Button
              onClick={generateTVMetrics}
              color="primary"
              disabled={loading.generateTVMetrics}
            >
              Generate TV Metrics{' '}
              {loading.generateTVMetrics && (
                <Spinner color="white" size="sm" />
              )}
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AdminTVUpload;
