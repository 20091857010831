import React, { useState } from "react"
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Nav, NavItem, NavLink, Spinner } from "reactstrap";
import classnames from "classnames";
import ApiService from "../services/ApiService";
import { connect } from "react-redux";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTheme } from "../utils/useTheme";

const EngagementGraph = ({
  date,
  tenant,
  overrideTenantId,
  useSourceFilter = false,
  total = false,
}) => {
  var darkTheme = true
  // check if light or dark mode
  if (useTheme() === "light-mode"){
    darkTheme = false
  }

const getChartOptions = (graphs, activeTab, useSourceFilter, filter, total, darkTheme) => {
  var backgroundColor = "#fff"
  var labelcolor = "#000"
  if (darkTheme === true) {
    backgroundColor = "#2B283B"
    labelcolor = "#fff"
  } 
  
  const graph = graphs[activeTab] || { data: { series: [] } };
  const series = useSourceFilter
    ? graph.data.series.filter((serie) => serie.source === filter)
    : graph.data.series;
  if (total) {
    series.unshift({
      color: "#2B95BA",
      dashStyle: "dot",
      visible: false,
      data: series
        .filter((serie) => serie.variant === "sum")
        .reduce((acc, serie) => {
          return serie.data.map((value, index) => {
            return (acc[index] || 0) + value;
          });
        }, []),
      name: "Total",
      source: filter,
    });
  }
  return {
    credits: { enabled: false },
    chart: {
      type: "spline",
      backgroundColor: backgroundColor,  // <-- Add background color

    },
    title: {
      text: "",
    },
    yAxis: {
      DashStyle: "ShortDash",
      title: {
        text: graph.data.yTitle,
      },
      labels: {
        style: {
          color: labelcolor
       }
      }
    },
    xAxis: {
      categories: graph.data.labels,
      labels: {
        style: {
          color: labelcolor
       }
      },
      DashStyle: "ShortDash",
      title: {
        text: graph.data.xTitle,
      },
      
    },
    series: JSON.parse(JSON.stringify(series)),
  };
};

  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }
  const { startDate, endDate } = date;
  const {
    data: graphs,
    isLoading,
    isRefetching,
    isSuccess,
  } = useQuery({
    queryKey: ["engagement", tenant.id, overrideTenantId, startDate, endDate],
    queryFn: () => ApiService.getEngagement(overrideTenantId, since, until),
    select: (data) => data.data.graphs,
    enabled,
    placeholderData: { data: { graphs: [] } },
  });
  const [activeTab, setActiveTab] = useState(1);// default to monthly
  const [filter, setFilter] = useState("facebook");
  const [chartOptions, setChartOptions] = useState(
    getChartOptions(graphs, activeTab, useSourceFilter, filter, total, darkTheme)
  );

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    setChartOptions(
      JSON.parse(
        JSON.stringify(
          getChartOptions(graphs, activeTab, useSourceFilter, filter, total, darkTheme)
        )
      )
    );
  }, [graphs, activeTab, useSourceFilter, filter, total, darkTheme]);

  const renderTab = (graph, index) => {
    return (
      <NavItem key={index}>
        <NavLink
          href="#"
          className={classnames({ active: activeTab === index })}
          onClick={() => toggleTab(index)}
        >
          {graph.source}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <>
      {(isLoading || isRefetching) && (
        <Spinner
          color="primary"
          size="sm"
          style={{ position: "absolute", right: 20 }}
        />
      )}
      {useSourceFilter && (
        <Nav pills className="navtab-bg my-4 center-pills">
          <NavItem>
            <NavLink
              href="#"
              className={classnames({ active: filter === "facebook" })}
              onClick={() => setFilter("facebook")}
            >
              Facebook
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              className={classnames({ active: filter === "instagram" })}
              onClick={() => setFilter("instagram")}
            >
              Instagram
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              className={classnames({ active: filter === "google" })}
              onClick={() => setFilter("google")}
            >
              Google
            </NavLink>
          </NavItem>
        </Nav>
      )}
      {isSuccess && (
        <>
          <Nav pills className="navtab-bg my-4 center-pills">
            {graphs.map((graph, index) => renderTab(graph, index))}
          </Nav>
          {graphs[activeTab] && (
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  date: state.date,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(EngagementGraph);