import React from "react"
import Highcharts from 'highcharts'
import { Spinner } from 'reactstrap'
import HighchartsReact from 'highcharts-react-official'
import ApiService from '../services/ApiService'
import { connect } from "react-redux"
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "../utils/useTheme";

const PressCountBarChart = ({ date, tenant }) => {
  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }
  var darkTheme = true
  // check if light or dark mode
  if (useTheme() === "light-mode"){
    darkTheme = false
  }

  const { data, isLoading, isRefreshing, isSuccess } = useQuery({
    queryKey: ["brandPressCount", tenant.id, startDate, endDate],
    queryFn: () => ApiService.getBrandPressCount(since, until),
    select: (data) => data.data,
    enabled,
    placeholderData: { data: [] },
  });

  const chartOptions = (graph) => {
    var backgroundColor = "#fff"
    var labelcolor = "#000"
    if (darkTheme === true) {
      backgroundColor = "#2B283B"
      labelcolor = "#fff"
    } 
    return {
credits: { enabled: false },
      chart: {
        type: "bar",
        height: "700px",
        backgroundColor: backgroundColor,  // <-- Add background color
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: data.yTitle,
        },
        labels: {
          style: {
            color: labelcolor
         }
        }
      },
      xAxis: {
        categories: data.labels,
        labels: {
          rotation: -45,
          style: {
            color: labelcolor
         }
        },
        title: {
          text: data.xTitle,
        },

      },
      series: data.series,
      plotOptions: {
        column: {
          stacking: "normal",
        },
        series: {
          borderColor: null,
        },
        dataSorting: {
            enabled: true,
          },
      },
    };
  };

  return (
    <>
      {isLoading && isRefreshing && <Spinner color="primary" />}
      {isSuccess && (
        <HighchartsReact highcharts={Highcharts} options={chartOptions()} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(PressCountBarChart);
