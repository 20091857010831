import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { useSponsorValuePerSource } from "../query/hooks";
import { useTheme } from "../utils/useTheme";

const SponsorSourceDividedValueChart = ({
  sponsorObjectId,
  selectedCurrency,
  tenant,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  var darkTheme = true
  // check if light or dark mode
  if (useTheme() === "light-mode"){
    darkTheme = false
  }

  const {
    data: sources,
    isLoading,
    isRefetching,
    isSuccess,
  } = useSponsorValuePerSource(sponsorObjectId);

  const chartOptions = (source) => {
    var labelcolor = '#505050'
    if (darkTheme === true) {
      labelcolor = '#fff'
    }

    return {
credits: { enabled: false },
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: source.data.yTitle,
        },
        labels: {
          style: {
            color: labelcolor
          }
        },
      },
      xAxis: {
        categories: source.data.labels,
        title: {
          text: source.data.xTitle,
        },
        labels: {
          style: {
            color: labelcolor

          }
        },
      },
      series: source.data.series.map((serie) => ({
        ...serie,
        data: serie.data.map((serieValue) =>
          Math.floor(serieValue * selectedCurrency.value)
        ),
        borderColor: null,  
      })),
      plotOptions: {
        column: {
          stacking: "normal",
        },
      },
    };
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const renderTab = (source, index) => {
    return (
      <NavItem key={index}>
        <NavLink
          href="#"
          className={classnames({
            active: activeTab === index,
          })}
          onClick={() => {
            toggleTab(index);
          }}
        >
          {source.source}
        </NavLink>
      </NavItem>
    );
  };

  const renderSource = (source, index) => {
    return (
      <TabPane tabId={index} className="p-3" key={index}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions(source)}
        />
      </TabPane>
    );
  };

  return (
    <>
      {(isLoading || isRefetching) && <Spinner color="primary" />}
      {isSuccess && (
        <>
          <Nav pills className="navtab-bg my-4 center-pills">
            {sources.map((source, index) => renderTab(source, index))}
          </Nav>
          <TabContent activeTab={activeTab}>
            {sources.map((source, index) => renderSource(source, index))}
          </TabContent>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedCurrency: state.currency.selectedCurrency,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(SponsorSourceDividedValueChart);
