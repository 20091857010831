import React, {useState} from 'react'
import {Form, FormGroup, Label, Input, Button} from 'reactstrap'
import ApiService from '../services/ApiService';

const AdminInstagramJobs = () => {
    const [post, setPost] = useState(false)
    const [insight, setInsight] = useState(false)
    const [stats, setStats] = useState(false)

    const toggleCheckBox = (type) => {
        switch (type) {
            case 'post':
                setPost(!post)
                break;
            case 'insight':
                setInsight(!insight)
                break;
            default:
                setStats(!stats)
                break;
        }
    }

    const handleSubmit = () => {
        const runJobs = async () => {
            const res = await ApiService.runInstagramJobs(post, insight, stats);
            const status = res.status;
            if(status===200){
                console.log("Wahey")
            }
        }

        if(post || insight || stats){
            runJobs();
        }
    }

  return (
    <>
    <Form style={{ paddingLeft: '30px' }}>
        <FormGroup>
            <Label check>
                <Input type="checkbox" onClick={()=>toggleCheckBox('post')} value={post}></Input>
                Fetch Instagram posts
            </Label>
        </FormGroup>
        <FormGroup>
            <Label check>
                <Input type="checkbox" onClick={()=>toggleCheckBox('insight')} value={insight}></Input>
                Update Instagram insights
            </Label>
        </FormGroup>
        <FormGroup>
            <Label check>
                <Input type="checkbox" onClick={()=>toggleCheckBox('stats')} value={stats}></Input>
                Update Instagram stats
            </Label>
        </FormGroup>
        <Button onClick={()=>handleSubmit()}>Run</Button>
    </Form>
    </>
  )
}

export default AdminInstagramJobs