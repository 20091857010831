import React from 'react'
import { Col, Row, Spinner } from 'reactstrap'

import RadialChart from './RadialChart'
import ApiService from '../services/ApiService'
import numeral from 'numeral'
import 'numeral/locales'
import { useTranslation } from 'react-i18next'
import { useQuery } from "@tanstack/react-query";
import { connect } from "react-redux";

const StadiumSellOut = ({ tenant }) => {
  const { t } = useTranslation();
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["StadiumSellOut", tenant.id],
    queryFn: ApiService.getStadiumSellOut,
    select: (data) => data.data,
    placeholderData: {
      data: {
        seasonTickets: { status: 0, actual: 0 },
        multipleTickets: { status: 0, actual: 0 },
        singleTickets: { status: 0, actual: 0 },
        newsletter: { status: 0, actual: 0 },
      },
    },
  });

  return (
    <>
      <Row>
        <Col md={6} lg={6}>
          <Row>
            <Col lg={6} xl={3} style={{ marginTop: "50px" }}>
              <RadialChart percent={data?.seasonTickets?.status ?? 0} />
              <h6 className="text-center">{t("Sesongkort")}</h6>
              <p className="text-center">
                {numeral(data?.seasonTickets?.actual).format("0,0")}
              </p>
            </Col>
            <Col lg={6} xl={3} style={{ marginTop: "50px" }}>
              <RadialChart percent={data?.multipleTickets?.status ?? 0} />
              <h6 className="text-center">{t("2+ billetter")}</h6>
              <p className="text-center">
                {numeral(data?.multipleTickets?.actual).format("0,0")}
              </p>
            </Col>
            <Col lg={6} xl={3} style={{ marginTop: "50px" }}>
              <RadialChart percent={data?.singleTickets?.status ?? 0} />
              <h6 className="text-center">{t("Enkeltbilletter")}</h6>
              <p className="text-center">
                {numeral(data?.singleTickets?.actual).format("0,0")}
              </p>
            </Col>
            <Col lg={6} xl={3} style={{ marginTop: "50px" }}>
              <RadialChart percent={data?.newsletter?.status ?? 0} />
              <h6 className="text-center">{t("Nyhetsbrev")}</h6>
              <p className="text-center">
                {numeral(data?.newsletter?.actual).format("0,0")}
              </p>
            </Col>
          </Row>
        </Col>
        <Col md={6} lg={6}>
          <h6 style={{ marginBottom: "30px" }}>STADIUM SELLOUT</h6>
          {(isLoading || isRefetching) && <Spinner color="primary" />}
          <p>{t("selloutExplanation")}</p>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(StadiumSellOut);