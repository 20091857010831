import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { FaFacebook, FaInstagram } from "react-icons/fa";
import useSocialMediaPost from '../query/hooks/useSocialMediaPost';
import CardSpinner from './CardSpinner';

const SocialMediaPostWidget = ({post, aspectRatio = '16 / 9', isLoadingPost = false}) => {
  const internalId = post?.facebook_post_id || post?.instagram_post_id || post?.id;
  const {data, isLoading} = useSocialMediaPost({...post, id: internalId}, !!post && !isLoadingPost);
  if (!post) {
    return null;
  }

  let Icon = FaFacebook;
  const media = post?.media?.toLowerCase?.() ?? 'facebook';
  if (media === 'instagram') {
    Icon = FaInstagram;
  }

  const shouldDisplayvidoe = media === 'instagram' && data?.type === 'VIDEO';

  return (
    <a href={post.permalink} style={{color: 'unset'}} target="_blank" rel="noreferrer">
      <Card style={{background: '#34314a', boxShadow: 'none'}}>
        <div style={{position: 'relative'}}>
          <CardSpinner loading={isLoading} />
          <div style={{position: 'absolute', top: 6, right: 6}}>
            <Icon style={{background: '#387A9E', padding: 2, width: 20, height: 20, borderRadius: '4px'}} />
          </div>
          {
            shouldDisplayvidoe ?
              <video src={data?.media_url} style={{width: '100%', height: 'auto', aspectRatio, objectFit: 'cover', borderRadius: '1rem 1rem 0 0'}} controls />
            :
              <img src={data?.media_url} style={{width: '100%', height: 'auto', aspectRatio, objectFit: 'cover', borderRadius: '1rem 1rem 0 0'}} alt="" />
          }
        </div>
        <CardBody style={{fontSize: 12}}>
          <div className='truncate-3-lines'>
            {post.message} {shouldDisplayvidoe ? 'VIDEO' : 'IMAGE'}
          </div>
          {/* <hr /> */}
          {/* <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div className="social-media-post-widget-icon">
              <FaHeart color='#D53333' />
              1.5k
            </div>
            <div className="social-media-post-widget-icon">
              <FaRegComment />
              187
            </div>
            <div className="social-media-post-widget-icon">
              <FaTelegramPlane />
              298
            </div>
          </div> */}
        </CardBody>
      </Card>
    </a>
  );
};


export default SocialMediaPostWidget;