import React from 'react'
import AccessTokenTable from './AccessTokenTable'
import { Row, Col, Card, CardBody } from 'reactstrap'
import UserCreationForm from './UserCreationForm'
import UserRemovalTable from './UserRemovalTable'
import PageAccessTokenTable from './PageAccessTokenTable'

const AdminUserManagement = () => {
  return (
    <>
        <Row className='mb-4'>
            <Col sm={6} md={6}>
                <Card>
                    <CardBody>
                        <h5 className="card-title mb-4">User creation</h5>
                        <UserCreationForm />
                    </CardBody>
                </Card>
            </Col>
            <Col sm={6} md={6}>
                <Card>
                    <CardBody>
                        <h5 className="card-title mb-4">User removal</h5>
                        <UserRemovalTable />
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <Row className='mb-4'>
            <Col sm={6} md={6}>
                <Card>
                    <CardBody>
                        <h5 className="card-title mb-4">User access tokens</h5>
                        <AccessTokenTable />
                    </CardBody>
                </Card>
            </Col>
            <Col sm={6} md={6}>
                <Card>
                    <CardBody>
                        <h5 className="card-title mb-4">Facebook page access tokens</h5>
                        <PageAccessTokenTable />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
  )
}

export default AdminUserManagement