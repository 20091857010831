import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import InfoTooltip from "../components/InfoTooltip";
import { useTranslation } from "react-i18next";

/* Components */
import DemographicsChart from "../components/DemographicsChart";
import LeagueTable from "../components/LeagueTable";
import { useParams } from "react-router-dom";
import EngagementGraph from "../components/EngagementGraph";
import VisibilityGraph from "../components/VisibilityGraph";
import LeagueClubFollowers from "../components/LeagueClubFollowers";

const ClubTables = () => {
  const { t } = useTranslation();
  const { tenantId = undefined } = useParams();

  return (
    <>
      <div className="container-fluid pt-4">
        <Row className="mb-4">
          <Col sm={16} md={12}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">{t("Liga Demografi")}</h4>
                <InfoTooltip
                  tooltip={t(
                    "Demografisk oversikt over alle alle sosiale medie profilene for alle klubbene og ligaen"
                  )}
                />
                <DemographicsChart overrideTenantId={tenantId} type="league" />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="container-fluid pt-4">
        <Row className="mb-4">
          <Col sm={16} md={12}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">{t("Følgere per klubb")}</h4>
                <InfoTooltip
                  tooltip={t(
                    "Viser antall følgere per klubb på sosiale medier"
                  )}
                />
                <LeagueClubFollowers overrideTenantId={tenantId}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="container-fluid pt-4">
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Engasjement")}
                  <InfoTooltip
                    tooltip={t(
                      "Viser utviklingen i engasjement (likes, delinger, kommentarer etc) per kanal måned for måned og akkumulert hittil i år."
                    )}
                  />
                </h4>
                <EngagementGraph
                  overrideTenantId={tenantId}
                  useSourceFilter
                  total
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Synlighet")}
                  <InfoTooltip
                    tooltip={t(
                      "Viser utviklingen i synlighet (visninger/impressions) per kanal måned for måned og akkumulert hittil i år."
                    )}
                  />
                </h4>
                <VisibilityGraph
                  overrideTenantId={tenantId}
                  useSourceFilter
                  total
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">{t("Fans")}</h4>
                <LeagueTable overrideTenantId={tenantId} table="fans" />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">{t("Ligatabell")}</h4>
                <LeagueTable overrideTenantId={tenantId} table="positions" />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">{t("Engasjement")}</h4>
                <LeagueTable overrideTenantId={tenantId} table="engagement" />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">{t("Synlighet")}</h4>
                <LeagueTable overrideTenantId={tenantId} table="visibility" />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ClubTables;
