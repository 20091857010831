import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { ATHLETE, MEDIA_VALUE_PERIOD } from "../keys";

const useMediaValuePeriod = () => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [ATHLETE, MEDIA_VALUE_PERIOD, tenant.id],
    queryFn: () => ApiService.getMediaValuePeriod(),
    select: (data) => JSON.parse(JSON.stringify(data.data.graphs)),
    placeholderData: { data: { graphs: [] } },
  });
};
export default useMediaValuePeriod;
