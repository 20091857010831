import React, { useState, useEffect } from 'react'
import ApiService from '../services/ApiService'
import { Form, FormGroup, Label, Button, Spinner, Input } from 'reactstrap'
import { toast } from 'react-toastify'

const UserCreationForm = () => {
	const [tenants, setTenants] = useState([])
	const [loaded, setLoaded] = useState(false)

	// User creation form states
	const [newUserName, setNewUserName] = useState('')
	const [newUserEmail, setNewUserEmail] = useState('')
	const [newUserTenant, setNewUserTenant] = useState(0)

	useEffect(() => {
		const fetchAllTenants = async () => {
			setLoaded(false)
			const res = await ApiService.getAllTenants()
			const data = res.data
			setTenants(data)
			setLoaded(true)
		}
		fetchAllTenants()
	}, [])

	const handleSubmit = () => {
		const createUser = async () => {
			await ApiService.createUser(
				newUserTenant,
				newUserName,
				newUserEmail
			)
		}

		if (newUserName.length && newUserEmail.length && newUserTenant) {
			createUser()
			toast.success('User successfully created.')
			setNewUserEmail('')
			setNewUserName('')
			setNewUserTenant(0)
		} else {
			toast.error('Please make sure all fields are filled in correctly.')
		}
	}

	console.log(newUserTenant)

	return (
		<>
			{loaded ? (
				<Form>
					<Label>User details</Label>
					<FormGroup>
						<Input
							type="name"
							name="name"
							placeholder="Full name"
							onChange={(e) => setNewUserName(e.target.value)}
							value={newUserName}
						/>
					</FormGroup>
					<FormGroup>
						<Input
							type="email"
							name="email"
							placeholder="Email"
							onChange={(e) => setNewUserEmail(e.target.value)}
							value={newUserEmail}
						/>
					</FormGroup>
					<Label>Connect to tenant</Label>
					<FormGroup>
						<Input
							type="select"
							name="select"
							onChange={(e) => setNewUserTenant(parseInt(e.target.value))}
							value={newUserTenant}>
							<option hidden>Tenant</option>
							{tenants.map((tenant) => (
								<option key={tenant.id} value={tenant.id}>
									{tenant.id} {tenant.name}
								</option>
							))}
						</Input>
					</FormGroup>
					<Button onClick={() => handleSubmit()}>Send activation e-mail</Button>
				</Form>
			) : (
				<>
					<Spinner color="primary" />
				</>
			)}
		</>
	)
}

export default UserCreationForm
