import React from "react";
import ApiService from "../services/ApiService";
import numeral from "numeral";
import "numeral/locales";
import { Spinner } from "reactstrap";
import { format } from "date-fns";
import { connect } from "react-redux";
import { useTable, useSortBy } from "react-table";
import { useQuery } from "@tanstack/react-query";
numeral.locale("no");

const LeagueTable = ({ table, date, tenant, overrideTenantId }) => {
  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }
  const {
    data: [data, totalRow],
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: [
      "leagueTable",
      table,
      tenant.id,
      overrideTenantId,
      startDate,
      endDate,
    ],
    queryFn: () =>
      ApiService.getLeagueTable(table, since, until, overrideTenantId),
    select: (data) => {
      return [data.data, data.data.total];
    },
    enabled,
    placeholderData: { data: { headers: [], rows: [], total: 0 } },
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: data.headers,
        data: data.rows,
      },
      useSortBy
    );

  return (
    <>
      {(isLoading || isRefetching) && <Spinner color="primary" />}
      <table
        className="table table-hover table-centered table-nowrap mb-0 text-white table-responsive"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  width={index === 0 ? "40%" : "20%"}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="mdi mdi-chevron-down"></span>
                      ) : (
                        <span className="mdi mdi-chevron-up"></span>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
          <tr className="totals">
            {Object.values(totalRow).map((cell) => (
              <td key={cell}>{cell}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(LeagueTable);
