import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { useTranslation } from "react-i18next";
import LineGraph from "../components/LineGraph";
import ValuePerPartnerTable from "../components/ValuePerPartnerTable";
import SourceDividedValueChart from "../components/SourceDividedValueChart";
import {
    usePartnerReach,
    usePartnerDividedValue,
    usePartnerEngagement,
    usePartnerEngagementValue,
    usePartnerDividedReach,
    usePartnerReachValue,
} from "../query/hooks";

const PersonValue = ({
selectedCurrency
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Row className="mb-4">
            <Col sm={12} md={6}>
              <Card className="mini-stat bg-card text-white">
                <CardBody>
                  <h4 className="card-title mb-4">
                    {t("Visninger per partner")}
                  </h4>
                  <LineGraph
                    useGraphQuery={usePartnerReach}
                    defaultActiveTab={0}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={6}>
              <Card className="mini-stat bg-card text-white">
                <CardBody>
                  <h4 className="card-title mb-4">
                    {t("Synlighetsverdi per partner")} (
                    {selectedCurrency.currency})
                  </h4>
                  <LineGraph
                    useGraphQuery={usePartnerReachValue}
                    defaultActiveTab={0}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={12} md={6}>
              <Card className="mini-stat bg-card text-white">
                <CardBody>
                  <h4 className="card-title mb-4">
                    {t("Engasjement per partner")}
                  </h4>
                  <LineGraph
                    useGraphQuery={usePartnerEngagement}
                    defaultActiveTab={0}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={6}>
              <Card className="mini-stat bg-card text-white">
                <CardBody>
                  <h4 className="card-title mb-4">
                    {t("Engasjementsverdi per partner")} (
                    {selectedCurrency.currency})
                  </h4>
                  <LineGraph
                    useGraphQuery={usePartnerEngagementValue}
                    defaultActiveTab={0}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={12} md={6}>
              <Card className="mini-stat bg-card text-white">
                <CardBody>
                  <h4 className="card-title mb-4">
                    {t("Visninger per kanal")}
                  </h4>
                  <LineGraph
                    useGraphQuery={usePartnerDividedReach}
                    defaultActiveTab={0}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={6}>
              <Card className="mini-stat bg-card text-white">
                <CardBody>
                  <h4 className="card-title mb-4">
                    {t("Sponsorverdi per kanal")} (
                    {selectedCurrency.currency})
                  </h4>
                  <LineGraph
                    useGraphQuery={usePartnerDividedValue}
                    defaultActiveTab={0}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={12} md={6}>
              <Card className="mini-stat bg-card text-white h-100">
                <CardBody>
                  <h4 className="card-title mb-4">
                    {t("Sponsorverdi per partner")} (
                    {selectedCurrency.currency})
                  </h4>
                  <ValuePerPartnerTable />
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={6}>
              <Card className="mini-stat bg-card text-white h-100">
                <CardBody>
                  <h4 className="card-title mb-4">
                    {t("Sponsorverdi per medie")} (
                    {selectedCurrency.currency})
                  </h4>
                  <SourceDividedValueChart />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
    );
}

const mapStateToProps = (state) => ({
    tenant: state.auth.tenant,
    selectedCurrency: state.currency.selectedCurrency,
})

export default connect(mapStateToProps, {})(PersonValue);
