import React, { Component } from 'react'
import ProfileMenu from '../components/ProfileMenu'
class CleanLayout extends Component {
  render() {
    return (
      <>
        <div id="layout-wrapper">
            <div className="float-right pr-2">
              <ProfileMenu />
            </div>
          <div className="main-content">
          
            <div className="page-content">{this.props.children}</div>
          </div>
        </div>
      </>
    );
  }
}

export default CleanLayout
