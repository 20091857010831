import { SET_DATE } from '../actions/types';

const initialState = {
	// startDate: new Date().setFullYear(new Date().getFullYear() - 1),
  startDate: new Date(new Date().getFullYear() -1, new Date().getMonth(), 1),
	// endDate: new Date().setMonth(new Date().getMonth() -1),
  endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),

}

const dateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      }
    default:
	    return state
  }
};

export default dateReducer;
