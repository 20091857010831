import React from 'react';
import { Col, Row } from 'reactstrap';

const Profile = ({ src, alt}) => {
  
  return (
    <div className="circle-image-container">
      <Row className="mb-4">
        <Col className="mb-4 mb-lg-0">
          <img src={src} alt={alt} className="circle-image" />
        </Col>
      </Row>
    </div>
    )
};

export default Profile;