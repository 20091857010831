import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { DATA_SOURCES } from "../keys";

const useDataSources = () => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [DATA_SOURCES, tenant.id],
    queryFn: () => ApiService.getSocialMediaState(),
    select: (data) => data.data,
    placeholderData: [{data: []}],
  });
};

export default useDataSources;
