import { Card, CardBody } from "reactstrap";
import { FaTiktok, FaFacebook, FaYoutube, FaXTwitter, FaInstagram } from "react-icons/fa6";

const SocialMediaMetric = ({source, metric, value, growth}) => {
  const icon = {
    instagram: <FaInstagram size={32} color="#377B9E" />,
    facebook: <FaFacebook size={32} color="#377B9E" />,
    youtube: <FaYoutube size={32} color="#377B9E" />,
    tiktok: <FaTiktok size={32} color="#377B9E" />,
    x: <FaXTwitter size={32} color="#377B9E" />,
  };


	return (
		<Card className="">
      <CardBody>
        <div style={{display: 'flex', justifyContent: 'space-between', gap: 16}}>
          <div style={{display: 'flex', gap: 16, alignItems: 'center' }}>
            {icon[source]}
            <div>
              <p
                style={{textTransform: 'capitalize'}}
                className="font-size-16 m-0 font-weight-medium font-color-white"
              >{source}</p>
              <p className="font-size-12 m-0">{metric}</p>
            </div>
          </div>
          <div style={{textAlign: 'right'}}>
            <p className="font-size-16 m-0 font-weight-bold font-color-white">{value}</p>
            <p className="font-size-12 m-0 font-weight-bold text-success">{growth}</p>
          </div>
        </div>
      </CardBody>
    </Card>
);
}

export default SocialMediaMetric;
