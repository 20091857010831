import { Card, CardBody } from "reactstrap";
import { FaTiktok, FaFacebook, FaYoutube, FaXTwitter, FaInstagram } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { BOXED_METRIC } from "../query/keys";
import { useSelector } from "react-redux";
import ApiService from "../services/ApiService";
import { format } from "date-fns";
import CardSpinner from "./CardSpinner";

const BoxedMetric = ({
    icon = null,
    metric,
    colour = "",
    link = "",
    titleFn = (primary, secondary) => '',
    primaryFn = (primary) => '',
    secondaryFn = (secondary) => '',
    enabled = false,
}) => {
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);
  const since = format(date.startDate, "yyyy-MM-dd");
  const until = format(date.endDate, "yyyy-MM-dd");

  const {
    data,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: [BOXED_METRIC, tenant.id, metric, since, until],
    queryFn: () => ApiService.getBoxedMetrics(metric, since, until),
    select: (data) => data.data,
    placeholderData: { data: {primary: null, secondary: null} },
  });

  const icons = {
    instagram: <FaInstagram size={32} color="white" />,
    facebook: <FaFacebook size={32} color="white" />,
    youtube: <FaYoutube size={32} color="white" />,
    tiktok: <FaTiktok size={32} color="white" />,
    x: <FaXTwitter size={32} color="white" />,
  };

  let cardStyle = {}
  switch (colour) {
    case 'red':
      cardStyle = { background: 'linear-gradient(to bottom right, #783550, #4F2033)', color: 'white' }
    break;

    case 'violet':
      cardStyle = { background: 'linear-gradient(to bottom right, #373064, #2A2549)', color: 'white'}
    break;

    case 'blue':
      cardStyle = { background: 'linear-gradient(to bottom right, #387A9E, #1F4A61)', color: 'white' }
    break;

    case 'greyblue':
      cardStyle = { background: 'linear-gradient(to bottom right, #4C7084, #343147)', color: 'white' }
    break;

    case 'yellow':
      cardStyle = { background: 'linear-gradient(to bottom right, #E4BE50, #806412)', color: 'white' }
    break;

    case 'dark':
      default:
        cardStyle = {};
      break;
  }
  const primary = data?.primary;
  const secondary = data?.secondary ?? 0;

  const content = (
    <div style={{display: 'flex', justifyContent: 'center', gap: 16, alignItems: 'center'}}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 16 }}>
        {icon && icons[icon]}
        <div>
          <p
            style={{textTransform: 'capitalize'}}
            className="font-size-18 m-0 font-weight-medium font-color-white"
          >
            {titleFn(primary, secondary)}
          </p>
          <p className="font-size-14 m-0 font-color-white">{primaryFn(primary)}</p>
          <p className="font-size-14 m-0 font-color-white">{secondaryFn(secondary)}</p>
        </div>
      </div>
    </div>
  );

  return (
    <Card className="text-center w-100 h-100" style={cardStyle}>
      <CardBody className="p-x-12" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <CardSpinner loading={isLoading || isRefetching} />
        {link ? <Link to={link} style={{textDecoration: 'none'}}>{content}</Link> : <>{content}</>}
      </CardBody>
    </Card>
  );

};

export default BoxedMetric;