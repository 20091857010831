import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { ATHLETE, SPONSOR_BRAND_VALUE } from "../keys";

const useSponsorsValue = () => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [ATHLETE, SPONSOR_BRAND_VALUE, tenant.id],
    queryFn: () => ApiService.getSponsorsValue(),
    select: (data) => JSON.parse(JSON.stringify(data.data)),
    placeholderData: [],
  });
};

export default useSponsorsValue;
