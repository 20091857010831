import React, { useEffect, useState } from 'react'
import ApiService from '../services/ApiService'
import { Table, Spinner } from 'reactstrap'

const PageAccessTokenTable = () => {
	const [loaded, setLoaded] = useState(false)
	const [data, setData] = useState([])

	useEffect(() => {
		const fetchAccessTokens = async () => {
			setLoaded(false)
			const res = await ApiService.getPageAccessTokens()
			const data = res.data
			setLoaded(true)
			setData(data)
		}
		fetchAccessTokens()
	}, [])

	return (
		<>
			{loaded ? (
				<div style={{ height: '495px', overflowY: 'scroll' }}>
					<Table striped borderless size="sm">
						<thead>
							<tr style={{ textAlign: 'center' }}>
								<th style={{ color: '#9d9d9d' }}>Tenant ID</th>
								<th style={{ color: '#9d9d9d' }}>Tenant</th>
								<th style={{ color: '#9d9d9d' }}>Page</th>
								<th style={{ color: '#9d9d9d' }}>Last updated</th>
								<th style={{ color: '#9d9d9d' }}>Status</th>
							</tr>
						</thead>
						<tbody>
							{data.map((entry, index) => (
								<tr
									key={`token-${index}-${entry.page}`}
									style={{ textAlign: 'center' }}>
									<td>{entry.tenant_id}</td>
									<td>{entry.tenant_name}</td>
									<td>{entry.page_name}</td>
									<td>{entry.updated_at.split(' ')[0]}</td>
									
									<td
										style={{
											fontSize: '20px',
											color: entry.status === 'active' ? 'green' : 'red',
										}}>
										{entry.status === 'active' ? (
											<i className="mdi mdi-checkbox-marked-circle"></i>
										) : (
											<i className="mdi mdi-alert-circle"></i>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			) : (
				<>
					<Spinner color="primary" />
				</>
			)}
		</>
	)
}

export default PageAccessTokenTable
