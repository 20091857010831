import React, { useState } from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import ApiService from '../services/ApiService';

const AdminFacebookJobs = () => {
    const [page, setPage] = useState(false);
    const [post, setPost] = useState(false);
    const [insight, setInsight] = useState(false);
    const [stats, setStats] = useState(false);

    const toggleCheckBox = (type) => {
        switch (type) {
            case 'page':
                setPage(!page)
                break;
            case 'post':
                setPost(!post)
                break;
            case 'insight':
                setInsight(!insight)
                break;
            default:
                setStats(!stats)
                break;
        }
    }

    const handleSubmit = () => {
        const runJobs = async () => {
            const res = await ApiService.runFacebookJobs(page, post, insight, stats);
            const status = res.status;
            if(status===200){
                console.log("Wahey")
            }
        }

        if(page || post || insight || stats){
            runJobs();
        }
    }

  return (
    <>        
        <Form style={{ paddingLeft: '30px' }}>
            <FormGroup>
                <Label check>
                    <Input type="checkbox" onClick={()=>toggleCheckBox('page')} value={page}></Input>
                    Fetch Facebook pages
                </Label>
            </FormGroup>
            <FormGroup>
                <Label check>
                    <Input type="checkbox" onClick={()=>toggleCheckBox('post')} value={post}></Input>
                    Update Facebook posts
                </Label>
            </FormGroup>
            <FormGroup>
                <Label check>
                    <Input type="checkbox" onClick={()=>toggleCheckBox('insight')} value={insight}></Input>
                    Update Facebook insights
                </Label>
            </FormGroup>
            <FormGroup>
                <Label check>
                    <Input type="checkbox" onClick={()=>toggleCheckBox('stats')} value={stats}></Input>
                    Update Facebook stats
                </Label>
            </FormGroup>
            <Button onClick={()=>handleSubmit()}>Run</Button>
        </Form>
    </>
  )
}

export default AdminFacebookJobs