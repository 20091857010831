import React from "react";
import ApiService from "../services/ApiService";
import { Spinner } from "reactstrap";
import numeral from "numeral";
import "numeral/locales";
import { connect } from "react-redux";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

numeral.locale("no");

const ValuePerPartnerTable = ({ date, selectedCurrency, tenant, selectedTenantId }) => {
  const { t } = useTranslation();

  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["partnerValuePerPartner", tenant.id, selectedTenantId, startDate, endDate],
    queryFn: () => ApiService.getPartnerValuePerPartner(selectedTenantId, since, until),
    select: (data) => data.data,
    enabled,
    placeholderData: { data: [] },
  });

  const renderRow = (row, index) => {
    return (
      <tr key={`${index}`}>
        <th>{row.sponsor}</th>
        <td className="text-center">{numeral(row.reach).format("0,0")}</td>
        <td className="text-center">
          {numeral(row.value * selectedCurrency.value).format("0,0")}
        </td>
        <td className="text-center">{numeral(row.exposures).format("0,0")}</td>
        <td className="text-center">
          {numeral(row.duration).format("0,0")} min
        </td>
      </tr>
    );
  };

  return (
    <>
      {(isLoading || isRefetching) && <Spinner color="primary" />}
      <table className="table table-hover table-centered table-nowrap mb-0 text-white table-responsive">
        <thead>
          <tr>
            <th scope="col" width="40%" className="border-top-0">
              Partner
            </th>
            <th scope="col" width="20%" className="text-center border-top-0">
              {t("VISNINGER")}
            </th>
            <th scope="col" width="20%" className="text-center border-top-0">
              {t("Verdi")}
            </th>
            <th scope="col" width="20%" className="text-center border-top-0">
              {t("Eksponeringer")}
            </th>
            <th scope="col" width="20%" className="text-center border-top-0">
              {t("Eksponeringstid")}
            </th>
          </tr>
        </thead>
        <tbody>{data?.map?.((row, index) => renderRow(row, index))}</tbody>
      </table>
    </>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  selectedCurrency: state.currency.selectedCurrency,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(ValuePerPartnerTable);
