import { Box, Card, Container, Flex, Grid, IconButton, Text, Theme, Tooltip } from '@radix-ui/themes';
import '@radix-ui/themes/styles.css';
import {FaArrowDown, FaArrowUp, FaInfo, FaUser} from 'react-icons/fa';
import {MdDarkMode} from 'react-icons/md'
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
import foqus_neg from "../assets/images/Logo_Foocus_Neg.png";

const Widget = ({title, value, arrow}) => (
	<Card>
		<Flex justify="between">
			<Text weight={"bold"}>{title}</Text>
			<Tooltip content="Add to library" id="heaasd">
				<IconButton size="1" radius="full">
					<FaInfo fontSize={"10"} />
				</IconButton>
			</Tooltip>
		</Flex>
		<Flex gap="2" align="center">
			<Text>{value}</Text>
			{arrow === 'up' && <FaArrowUp color="#05A399" />}
			{arrow === 'down' && <FaArrowDown color="#EC4461" />}
		</Flex>
	</Card>
);

const lineChartOptions = {
	credits: { enabled: false },
	exporting: { enabled: false },
	chart: {
		type: "spline",
	},
	title: {
		text: "",
	},
	yAxis: {
		title: {
			text: "Followers",
		},
	},
	xAxis: {
		categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
		labels: {
			rotation: 0,
		},
		title: {
			text: "Month",
		},
	},
	series: [{
		name: 'Tokyo',
		data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2]
	}, {
		name: 'London',
		data: [3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0]
	}, {
		name: 'New York',
		data: [2.0, 2.2, 3.3, 4.5, 6.3, 8.5, 10.0],
	}],
};

const barChartOptions = {
	credits: { enabled: false },
	exporting: { enabled: false },
	chart: {
		type: "bar",
	},
	title: {
		text: "",
	},
	yAxis: {
		title: {
			text: "Followers",
		},
	},
	xAxis: {
		categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
		labels: {
			rotation: 0,
		},
		title: {
			text: "Month",
		},
	},
	series: [{
		name: 'Print',
		data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2]
	}, {
		name: 'Online',
		data: [3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0]
	}],
};

const GraphCard = ({options}) => (
	<Card>
		<Flex justify="center">
			<Text size="4" m="2" weight="bold">Engasjement kanaler</Text>
		</Flex>
		<HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
	</Card>
);

const RadixTest = () => {
	return (
		<Theme
			appearance="dark"
			panelBackground='solid'
			accentColor="blue"
			grayColor="gray"
			scaling="100%"
			radius="full"

		>
			<Box width={"100%"} height="80px" color="gray" style={{background: '#191919'}} mb="4">
				<Container height="80px">
					<Flex justify="between" align="center" gap="4" height="80px">
						<Flex>
							<img src={foqus_neg} alt="Foqus logo" style={{width: 'auto', height: '70px'}} />
						</Flex>
						{/* Navigation */}
							<Flex gap="4">
								<Text>Dashboard</Text>
								<Text>Fans</Text>
								<Text>Media</Text>
								<Text>Arena</Text>
								<Text>Rapport</Text>
								<IconButton>
									<MdDarkMode />
								</IconButton>
								<IconButton>
									<FaUser />
								</IconButton>
							</Flex>
					</Flex>
				</Container>
			</Box>
			<Container pb="9">
				<Flex direction="column" gap="4">
					<Grid columns={{ initial: '2', md: '4' }} gap="4" width="auto">
						<Widget title="Engasjement" value="1 552 728" arrow="down" />
						<Widget title="Visninger" value="13 998 603" arrow="up" />
						<Widget title="Publikum" value="39 350 " arrow="down" />
						<Widget title="Enkeltbilletter" value="35 023" arrow="up" />
					</Grid>
					<Grid columns={{ initial: '1', md: '2' }} gap="4" width="auto">
						<GraphCard options={lineChartOptions}></GraphCard>
						<GraphCard options={barChartOptions}></GraphCard>
						<GraphCard options={barChartOptions}></GraphCard>
						<GraphCard options={lineChartOptions}></GraphCard>
					</Grid>
				</Flex>
			</Container>
		</Theme>
	)
}

export default RadixTest;