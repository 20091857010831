import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { DATA_SOURCES } from "../keys";
import { toast } from "react-toastify";

const useToggleDataSourcesMutation = () => {
  const tenant = useSelector((state) => state.auth.tenant);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [DATA_SOURCES, tenant.id],
    mutationFn: ApiService.setSocialMediaState,

    onMutate: async (newDataSource) => {
      await queryClient.cancelQueries({ queryKey: [DATA_SOURCES, tenant.id] });

      const previousDataSources = queryClient.getQueryData([DATA_SOURCES, tenant.id]);

      queryClient.setQueryData([DATA_SOURCES, tenant.id], (oldData) => {
        return {
          ...oldData,
          data: oldData.data.map((dataSource) => {
            if (dataSource.provider === newDataSource.provider) {
              return {
                ...dataSource,
                accessTokens: dataSource.accessTokens.map((accessToken) => {
                  return {
                    ...accessToken,
                    accounts: accessToken.accounts.map((account) => {
                      if (account.id === newDataSource.accountId) {
                        return {
                          ...account,
                          include: newDataSource.newState,
                        };
                      }

                      return account;
                    }),
                  };
                })
              }
            }

            return dataSource;
          }),
        };
      });

      return { previousDataSources };
    },
    onError: (error) => {
      console.log(error);
      toast.error("Failed to toggle data sources");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [DATA_SOURCES, tenant.id] })
    },
  });
};

export default useToggleDataSourcesMutation;
