import React, { useState } from 'react';
import { Card, CardBody, FormGroup, Button, Spinner } from 'reactstrap';

const AdminFileUpload = ({type, accept, apiRequest}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const onFileChange = (event) => {
    console.log(event);
    setFile(event.target.files[0]);
  };

  const uploadFile = async () => {
    if (!file) {
      window.alert('Please select a file to import.');
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('file', file);
      await apiRequest(formData);
      window.alert('Success');
    } catch (err) {
      const errorMessage = err?.response?.data?.error ?? `An error occurred while importing ${type} data.`;
      window.alert(errorMessage);
    }

    setLoading(false);
  };

  console.log(file);

  return (
    <Card>
      <CardBody>
        <h5 className="card-title mb-4">Import {type} file</h5>
        <FormGroup>
          <label htmlFor={`file-upload-${type}`} className="custom-file-upload">
            <i className="ti-file mr-2"></i>
            {file ? file.name : 'Select file'}
          </label>
          <input
            onChange={onFileChange}
            id={`file-upload-${type}`}
            className='file-upload'
            type="file"
            accept={accept}
          />
          {file && (
            <Button
              size="sm"
              className="ml-4"
              color="transparent"
              onClick={() => setFile(null)}
            >
              <i className="ti-close" />
            </Button>
          )}
        </FormGroup>
        <Button
          onClick={uploadFile}
          disabled={loading || !file}
          color="primary"
        >
          Import {type}{' '}
          {loading && <Spinner color="white" size="sm" />}
        </Button>
      </CardBody>
    </Card>
  );
};

export default AdminFileUpload;
