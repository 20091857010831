import { useQuery } from "@tanstack/react-query";
import ApiService from "../../services/ApiService";
import { SOCIAL_MEDIA_POST_WIDGET} from "../keys";
import { useSelector } from "react-redux";

const useSocialMediaPost = (post, enabled) => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [
			SOCIAL_MEDIA_POST_WIDGET,
			tenant.id,
			post?.media?.toLowerCase?.() || '',
			post?.id
    ],
		enabled,
		keepPreviousData: false,
		staleTime: 1000 * 60 * 60,
    queryFn: () =>
      ApiService.getSocialMediaPost(post?.media?.toLowerCase?.(), post.id),
		select: (res) => {
			return res.data;
		},
  });
};
export default useSocialMediaPost;
