import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Globals } from "../Globals";
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import facebook from "../assets/images/facebook.png";
import google from "../assets/images/google.png";
import twitter from "../assets/images/twitter.png";
import fathom from "../assets/images/fathom.png";
import DataSource from "../components/DataSource";
import SocialService from "../services/SocialService";
import { useTranslation } from "react-i18next";

const DataSources = (props) => {
  const [state, setState] = useState({
    loading: false,
    accounts: [],
    data_sources_menu: false,
    account: null,
  });

  const { t } = useTranslation();

  const toggleAddDatasource = () => {
    setState((prevState) => ({
      ...prevState,
      data_sources_menu: !prevState.data_sources_menu,
    }));
  };


  useEffect(() => {
    const fetchSources = () => {
      const url = new URL(window.location.href);
      const accessTokenId = url.searchParams.get("accessTokenId");
      setState((prevState) => ({ ...prevState, loading: true }));
      axios.get(`${Globals.API_URL}/api/data-sources`).then((res) => {
        const accounts = res.data;
        const account =
          accounts.find(
            (account) => Number(account.id) === Number(accessTokenId)
          ) ?? accounts[0];
        console.log(accounts);
        setState((prevState) => ({
          ...prevState,
          accounts,
          account,
          loading: false,
        }));
      });
    };
  
    fetchSources();
  }, []);
  


  const selectAccount = (e, account) => {
    e.preventDefault();

    setState({ ...state, account });
  };

  const expiredOrNot = (status) => {
    if (status === "expired") {
      return <i className="mdi mdi-alert-circle">{t("Utløpt token")}</i>;
    }
  };

  const renderSource = (account, index) => {
    let logo = facebook;
    if (account.provider === "facebook") {
      logo = facebook;
    } else if (account.provider === "google") {
      logo = google;
    } else if (account.provider === "twitter") {
      logo = twitter;
    } else if (account.provider === "fathom") {
      logo = fathom;
    }

    const activeClass =
      state.account && account.id === state.account.id
        ? "active bg-card-box"
        : "";
    var status = account.status;
    return (
      <Link
        onClick={(e) => selectAccount(e, account)}
        to="#"
        className={`media ${activeClass} rounded p-2`}
        key={index}
      >
        <img
          className="d-flex mr-3 rounded-circle"
          src={logo}
          alt="veltrix"
          height="36"
        />
        <div className="media-body chat-user-box overflow-hidden">
          <p className="user-title m-0 text-capitalize">{account.provider}</p>
          <p className="text-muted m-0 d-block text-truncate">
            {account.username}
          </p>
          <div className="sources">{expiredOrNot(status)}</div>
        </div>
      </Link>
    );
  };

  const { loading, accounts, account } = state;
  return (
    <>
      <div className="container-fluid">
        <Row className="align-items-center">
          <Col sm={6}>
            <div className="page-title-box">
              <h4 className="font-size-18">{t("Datakilder")}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="col-12">
            <div className="email-leftbar card">
              <div className="ml-1 mo-mb-2">
                <Dropdown
                  isOpen={state.data_sources_menu}
                  toggle={toggleAddDatasource}
                >
                  <DropdownToggle
                    tag="button"
                    color="primary"
                    className="btn btn-primary waves-effect waves-light btn-custom btn-block"
                    caret
                  >
                    {t("Legg til kilde")}
                    <i className="mdi mdi-chevron-down ml-1"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      tag="a"
                      href={SocialService.facebookLoginLink()}
                      target="_blank"
                    >
                      Facebook-konto (personlig)
                    </DropdownItem>
                    <DropdownItem
                      tag="a"
                      href={SocialService.facebookBusinessLoginLink()}
                      target="_blank"
                    >
                      Facebook-konto (business)
                    </DropdownItem>
                    <DropdownItem
                      tag="a"
                      href={SocialService.googleLoginLink()}
                      target="_blank"
                    >
                      Google-konto
                    </DropdownItem>
                    {/* <DropdownItem tag="a" href={SocialService.twitterLoginLink()} target="_blank">
Twitter-konto
</DropdownItem> /}
{/ removed because of unresolved twitter API issue */}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <h5 className="mt-4">{t("Datakilder")}</h5>
              <div className="mt-2 mx-n2">
                {loading && (
                  <div
                    className="spinner-border text-primary m-1"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                {!loading &&
                  accounts.map((account, index) =>
                    renderSource(account, index)
                  )}
              </div>
            </div>
            <div className="email-rightbar mb-3">
              <Card>
                <CardBody>
                  <DataSource account={account} />
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default DataSources;
