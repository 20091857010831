import React, { useState, useEffect } from 'react'
import ApiService from '../services/ApiService'
import {
	Button,
	Spinner,
	Table,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap'
import { toast } from 'react-toastify'

const UserRemovalTable = () => {
	const [loaded, setLoaded] = useState(false)
	const [modal, setModal] = useState(false)
	const [users, setUsers] = useState([])
	const [toRemove, setToRemove] = useState({})

	const fetchAllUsers = async () => {
		setLoaded(false)
		const res = await ApiService.getAllUsers()
		const data = res.data
		setUsers(data)
		setLoaded(true)
	}

	useEffect(() => {
		fetchAllUsers()
	}, [])

	const toggleModal = (user) => {
		setModal(!modal)
		setToRemove(user)
	}

	const handleRemoval = (user) => {
		// Open modal for confirmation. Display info of user that will be removed.
		const deleteUser = async () => {
			await ApiService.removeUser(user.id, user.name, user.email)
		}
		if (user) {
			deleteUser()
			setModal(!modal)
			setToRemove({})
			toast.success('User has been deleted.')
			fetchAllUsers()
		}
	}

	console.log('toRemove', toRemove)

	return (
		<>
			<Modal isOpen={modal} toggle={toggleModal}>
				<ModalHeader toggle={toggleModal}></ModalHeader>
				{toRemove && (
					<ModalBody>
						Are you sure you want to delete {toRemove.name} ({toRemove.email})?
					</ModalBody>
				)}
				<ModalFooter>
					<Button color="danger" onClick={() => handleRemoval(toRemove)}>
						Remove
					</Button>
					<Button color="primary" onClick={() => setModal(false)}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
			{loaded ? (
				<>
					<div style={{ height: '400px', overflowY: 'scroll' }}>
						<Table>
							<thead>
								<tr style={{ textAlign: 'center' }}>
									<th style={{ color: '#9d9d9d' }}>ID</th>
									<th style={{ color: '#9d9d9d' }}>Name</th>
									<th style={{ color: '#9d9d9d' }}>Email</th>
									<th>
										<span onClick={() => fetchAllUsers()} color="danger">
											<i className="ti-reload"></i>
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{users &&
									users.map((user) => (
										<tr key={`user-${user.id}`} style={{ textAlign: 'center' }}>
											<td>{user.id}</td>
											<td>{user.name}</td>
											<td>{user.email}</td>
											<td>
												<Button onClick={() => toggleModal(user)} color="danger">
													<i className="ti-trash"></i>
												</Button>
											</td>
										</tr>
									))}
							</tbody>
						</Table>
					</div>
				</>
			) : (
				<>
					<Spinner color="primary" />
				</>
			)}
		</>
	)
}

export default UserRemovalTable
