import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import {TENANT_PROFILE_DATA } from "../keys";

const useTenantProfileData = () => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [TENANT_PROFILE_DATA, tenant.id],
    queryFn: ApiService.getTenantProfileData,
    select: (data) => data.data,
    placeholderData: [{data: undefined}],
  });
};

export default useTenantProfileData;
