import React, { useEffect } from "react"
import { Route } from "react-router-dom"
import { connect } from 'react-redux'
//import PropTypes from 'prop-types'
import { useTheme } from "../utils/useTheme";
import { Spinner } from "reactstrap";


const AppRoute = ({
  tenant,
  component: Component,
  path,
  layout: Layout,
  user,
  loading,
  client,
  isAuthProtected,
  is_authenticated,
  ...rest
}) => {

  useEffect(() => {
    if ( loading || is_authenticated || !isAuthProtected) {
      return
    }
    const asyncLoginRedirect = async () => {
      await client.loginWithRedirect({
        appState: { targetUrl: path }
      })
    }
    asyncLoginRedirect()

  }, [loading, is_authenticated, isAuthProtected, client, path])

  const render = props =>
    (is_authenticated === true || !isAuthProtected) ? <Layout><Component {...props} /></Layout> : null
    if (useTheme() === "light-mode"){
      import('../sass-light/app.scss')
    } else {
      import('../sass-dark/app.scss')
    }

  if (isAuthProtected && !tenant) {
    return (
      <Spinner color="primary" />
    )
  }

  return (
    <Route path={path} render={render} {...rest} />
  )
}

const mapStateToProps = state => ({
  loading: state.auth.loading,
  user: state.auth.user,
  client: state.auth.client,
  is_authenticated: state.auth.is_authenticated,
  tenant: state.auth.tenant,
})

export default connect(mapStateToProps, {})(AppRoute)