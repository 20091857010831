import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import ApiService from '../services/ApiService';
import logo from '../assets/images/Logo_Foocus_Neg.png';

function RegistrationPageAthlete() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [, setErrorMessage] = useState('');


  const validateForm = () => {


    if (!name) {
      setErrorMessage('Please enter your name');
      return false;
    }

    if (!email) {
      setErrorMessage('Please enter your email address');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const handleSubmit = () => {

     const registerUser = async () => {
      const response = await ApiService.registerUser(name, email);
      console.log('Registration successful:', response.data);
      // Handle further logic here, e.g., redirecting to a login page or showing a success message
      //redirect to EmailNotificationPage
      window.location.href = '/EmailNotificationPage';
     }

     if (validateForm()) {
      registerUser();
     }

  };

  return (
      <>
        <div className="accountbg" />

        <div className="wrapper-page account-page-full">
          <Card>
            <CardBody>
              <h3 className="text-center">
                <Link to="/dashboard" className="logo">
                  <img src={logo} height="75" alt="logo" />
                </Link>
              </h3>

              <div className="p-3">
                <h4 className="font-18 m-b-5 text-center">Registration</h4>
                <p className="text-muted text-center">
                  Start optimising your sponsorships
                </p>

                <Form className="form-horizontal m-t-30">

                <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      type="name"
                      id="name"
                      placeholder="Enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="useremail">Email</Label>
                    <Input
                      type="email"
                      id="useremail"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>

                  <Row className="form-group m-t-20">
                    <Col md="12" className="text-right">
                      <Button
                        color="primary"
                        className="w-md waves-effect waves-light"
                        onClick={()=>handleSubmit()}
                      >
                        Register
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
          </Card>

          <div className="m-t-40 text-center">
            <p>
              Already have an account?{' '}
              <Link to="/login" className="font-500 text-primary">
                {' '}
                Login{' '}
              </Link>{' '}
            </p>
            <p>
              © {new Date().getFullYear()} Foocus AS
            </p>
          </div>
        </div>
      </>
    );
}

export default RegistrationPageAthlete;