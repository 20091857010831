import { SET_CURRENCIES, SET_SELECTED_CURRENCY } from "./types"

export const setSelectedCurrency = payload => dispatch => {
    dispatch({
        type: SET_SELECTED_CURRENCY,
        payload,
    })
}
export const setCurrencies = payload => dispatch => {
    dispatch({
        type: SET_CURRENCIES,
        payload,
    })
}