import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Spinner } from 'reactstrap';
import fileDownload from 'js-file-download';
import DatePicker from '../components/DatePicker';
import ApiService from '../services/ApiService';
import { format, lastDayOfMonth } from 'date-fns';
import { connect } from 'react-redux';
import Select from 'react-select';
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module';


TagManager.dataLayer({
  dataLayer: {
    event: 'pageview',
    pagePath: '/reports',
    pageTitle: 'Reports',
  },
});

const Reports = ({ tenant }) => {
  const { t } = useTranslation();
  const [contentType, setContentType] = useState('overview');
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [endDate, setEndDate] = useState(lastDayOfMonth(new Date()));
  const [loading, setLoading] = useState(false);
  const [isPartnerActive, setIsPartnerActive] = useState(false);
  const [isLoadingPartnerActive, setIsLoadingPartnerActive] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(undefined);
  const [partners, setPartners] = useState([]);

  const generateExport = async (type) => {
    setLoading(type);
    const partnerIds = selectedPartner.value !== 'all' ? selectedPartner.value : partners.slice(1).map(partner => partner.value);
    try {
      const data = await ApiService.getExportedReport(contentType, type, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'), partnerIds);
      fileDownload(data.data, `foqus-${type}-${format(startDate, 'MM-yyyy')}-${format(endDate, 'MM-yyyy')}.${type}`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end ? lastDayOfMonth(end) : undefined);
  };

  const leagueButtons = () => {
    const template = tenant.template
    if (template === 'league') {
      if (['NFF', 'Døds'].includes(tenant.name)) {
        return (
          null
          )
      } else {
        return (
          <>
            <button  onClick={() => setContentType('club')} className={contentType !== 'club' ? '' : 'reports-button-selected'}>{t('Klubb')}</button>
          </>
        );
      }
    }
  }

  const clubButtons = () => {
    const template = tenant.template;
    if (template === 'eliteserien') {
      return (
        <>
          <button  onClick={() => setContentType('media')} className={contentType !== 'media' ? '' : 'reports-button-selected'}>{t('Media')}</button>
          <button  onClick={() => setContentType('arena')} className={contentType !== 'arena' ? '' : 'reports-button-selected'}>{t('Arena')}</button>
        </>

      );
    }
    
    
  };

  useEffect(() => {
    const getPartnerActive = async () => {
      setIsLoadingPartnerActive(true);
      setContentType('overview');
      setSelectedPartner({ value: 'all', label: 'Alle' });
      setPartners([]);
      const resIsPartnerActive = await ApiService.getPartnerActive();
      const isActive = resIsPartnerActive?.data?.active ?? false
      setIsPartnerActive(isActive);
      if (isActive) {
        const resPartners = await ApiService.getPartners();
        const mappedPartners = resPartners.data.map(partner => ({
          value: partner.id,
          label: partner.name,
        }));

        setPartners([{ value: 'all', label: 'Alle' }, ...mappedPartners]);
        setSelectedPartner({ value: 'all', label: 'Alle' });
      }
      setIsLoadingPartnerActive(false);
    };
    getPartnerActive();
  }, [tenant]);

  const onChangePartner = (partner) => {
    setSelectedPartner(partner);
  }

  return (
    <div className="report container-fluid pt-4">
      <Row className="mb-4">
        <Col md={12}>
          <Card className="mini-stat bg-card">
            <CardBody>
              <h4 className="card-title mb-5 text-left">{t('Lag rapport')}</h4>
              <Row className="mb-5">
                <Col sm={2} className="d-flex align-items-center">
                  {t('Periode')}
                </Col>
                <Col sm={4}>
                  <DatePicker
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    showMonthYearPicker
                    dateFormat="MMM yyyy"
                    selectsRange
                  />
                </Col>
              </Row>
              <Row className="mb-5">
                <Col sm={2} xs={3} className="d-flex align-items-center">
                  {t('Innhold')}
                </Col>
                <Col md={10} sm={9}>
                  <div className="reports-button-list">
                    <button onClick={() => setContentType('overview')} className={contentType !== 'overview' ? '' : 'reports-button-selected'}>{t('Oversikt')}</button>
                    {/* <button onClick={() => setContentType('fans')} className={contentType !== 'fans' ? '' : 'reports-button-selected'}>Fans</button>
                    <button onClick={() => setContentType('media')} className={contentType !== 'media' ? '' : 'reports-button-selected'}>Media</button>
                    <button onClick={() => setContentType('brands')} className={contentType !== 'brands' ? '' : 'reports-button-selected'}>Brands</button>
                    <button onClick={() => setContentType('arena')} className={contentType !== 'arena' ? '' : 'reports-button-selected'}>Arena</button>*/}
                    { clubButtons() }
                    { leagueButtons() }
                    <button disabled={!isPartnerActive || isLoadingPartnerActive} onClick={() => setContentType('partner')} className={contentType !== 'partner' ? '' : 'reports-button-selected'}>
                      {isLoadingPartnerActive && <Spinner color="white" size="sm" />}
                      {t('Partner')}
                    </button>
                  </div>
                </Col>
              </Row>
              {contentType === 'partner' && (
                <Row className="mb-5">
                  <Col sm={2} className="d-flex align-items-center">
                    Partner
                  </Col>
                  <Col md={3}>
                    <Select
                      className="react-select__container"
                      classNamePrefix="react-select"
                      value={selectedPartner}
                      options={partners}
                      onChange={onChangePartner}
                      maxMenuHeight={200}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={2} sm={3} className="d-flex align-items-center">
                  {t('Eksporter som')}
                </Col>
                <Col md={10} sm={9}>
                  <div className="reports-button-list">
                    <button disabled={!!loading} onClick={() => generateExport('xlsx')} className="big reports-button-selected">
                      {loading === 'xlsx' && <Spinner color="white" size="sm" />}
                      <img src="/icons/excel.svg" alt="" />Excel
                    </button>
                    <button disabled={!!loading} onClick={() => generateExport('pptx')} className="big reports-button-selected">
                      {loading === 'pptx' && <Spinner color="white" size="sm" />}
                      <img src="/icons/powerpoint.svg" alt="" />PPTX
                    </button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row >
    </div >
  );
}

const mapStateToProps = state => ({
  tenant: state.auth.tenant,
})

export default connect(mapStateToProps)(Reports);