import React from 'react'
import { Col, Row } from 'reactstrap';
import AdminJobsPanel from '../components/AdminJobsPanel'
import AdminMeltwater from '../components/AdminMeltwater';
import AdminS3Upload from '../components/AdminS3Upload';
import AdminUserManagement from '../components/AdminUserManagement';
import AdminTVUpload from '../components/AdminTVUpload';
import AdminFileUpload from '../components/AdminFileUpload';
import ApiService from '../services/ApiService';

const AdminPanel = () => {

  return (
    <>
      <div className="container-fluid pt-4 mb-4">
        <AdminUserManagement />
        <AdminMeltwater />
        <AdminJobsPanel />
        <AdminS3Upload />
        <AdminTVUpload />
        <Row>
          <Col xs={6}>
            <AdminFileUpload type="Medietall" accept=".xlsx" apiRequest={ApiService.importMedietall} />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AdminPanel