import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../utils/useTheme";
import DatePicker from '../components/DatePicker';
import foqus_neg from "../assets/images/Logo_Foocus_Neg.png";
import foqus_pos from "../assets/images/Logo_Foocus_Pos.png";
import SettingDropdown from "../components/SettingDropdown";
import TenantDropdown from "../components/TenantDropdown";
import ProfileMenu from "../components/ProfileMenu";
import { connect } from "react-redux";
import LanguageSelect from "../components/LanguageSelect";
import { setDate } from "../store/actions/dateActions";

const TopBar = ({
  theme,
  isMenuOpened,
  toggleRightSidebar,
  openLeftMenuCallBack,
  tenant,
  menuOpen,
  date,
  setDate,
  themeBtn = true,
  fullscreen = true,
}) => {
  const {startDate, endDate} = date;

  // useEffect(() => {
  //   let isMounted = true; // track whether component is mounted
    
  //   const fetchData = async () => {
  //     const resIsExpired = await axios.get(
  //       `${Globals.API_URL}/api/data-sources/is-tenant-expired/${tenant.id}`
  //     );
  //     if (isMounted) {
  //       setisExpired(resIsExpired.data === "expired");
  //     }
  //   };
  
  //   fetchData();
  
  //   return () => {
  //     isMounted = false; // cleanup toggles value, if unmounted
  //   };
  // }, [tenant.id]);
  
  const onChange = (dates) => {
    const [start, end] = dates;
    setDate({
      startDate: new Date(new Date(start).getFullYear(), new Date(start).getMonth(), 1),
      endDate: end ? new Date(new Date(end).getFullYear(), new Date(end).getMonth() + 1, 0) : undefined,
    });
  };

  const Controls = (tenant) => {
    if (tenant.type === "person") {
      return (
        <>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            showMonthYearPicker
            dateFormat="MMM yy"
            selectsRange
          />
          <ProfileMenu />
          <SettingDropdown />
        </>
      );
    } else {
      return(
        <>
        <button
          type="button"
          className="btn btn-sm font-size-24 d-lg-none header-item waves-effect waves-light"
          onClick={() => openLeftMenuCallBack()}
        >
          <i className="mdi mdi-menu"></i>
        </button>
        {/* <CurrencySelect /> */}
        <LanguageSelect />
        <TenantDropdown />
        <ProfileMenu />
        <SettingDropdown />
        </>
      )
    }
  };

  return (
    <header id="page-topbar">
    <div className="navbar-header">
      <div className="d-flex">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img
                src={useTheme() === "dark-mode" ? foqus_neg : foqus_pos}
                alt=""
                height="40"
              />
            </span>
            <span className="logo-lg">
              <img
                src={useTheme() === "dark-mode" ? foqus_neg : foqus_pos}
                alt=""
                height="70"
              />
            </span>
          </Link>
        </div>
        
      </div>
      <div className="d-flex">
        { Controls(tenant) }
      </div>
    </div>
  </header>
  );
};

const mapStateToProps = (state) => ({
  tenant: state.auth.tenant,
  isFullscreenEnabled: state.isFullscreenEnabled,
  date: state.date,
});

export default connect(mapStateToProps, { setDate })(TopBar);
