import React, { Component } from 'react'
class DummyValuePerPartnerTable extends Component {
	render() {
		return (
			<>
				<table className="table table-hover table-centered table-nowrap mb-0 text-white table-responsive">
					<thead>
						<tr>
							<th scope="col" width="40%" className="border-top-0">Partner</th>
							<th scope="col" width="20%" className="text-center border-top-0">Verdi<br />(MNOK)</th>
							<th scope="col" width="20%" className="text-center border-top-0">Eksponeringer</th>
                            <th scope="col" width="20%" className="text-center border-top-0">Eksponeringstid<br />(min)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
                            <th>Telenor</th>
                            <td className="text-center">5,1</td>
                            <td className="text-center">1122</td>
                            <td className="text-center">121</td>
                        </tr>
                        <tr>
                            <th>DNB</th>
                            <td className="text-center">4,2</td>
                            <td className="text-center">883</td>
                            <td className="text-center">102</td>
                        </tr>
                        <tr>
                            <th>Helly Hansen</th>
                            <td className="text-center">3,5</td>
                            <td className="text-center">455</td>
                            <td className="text-center">56</td>
                        </tr>
                        <tr>
                            <th>Dun of Norway</th>
                            <td className="text-center">3,1</td>
                            <td className="text-center">433</td>
                            <td className="text-center">34</td>
                        </tr>
                        {/*<tr>
                            <th>OBOS</th>
                            <td className="text-center">2,4</td>
                            <td className="text-center">343</td>
                            <td className="text-center">35</td>
                        </tr>
                        <tr>
                            <th>Ringnes</th>
                            <td className="text-center">2,1</td>
                            <td className="text-center">235</td>
                            <td className="text-center">32</td>
                        </tr>
                        <tr>
                            <th>Salmar</th>
                            <td className="text-center">1,8</td>
                            <td className="text-center">452</td>
                            <td className="text-center">21</td>
                        </tr>
                        <tr>
                            <th>Trønderenergi</th>
                            <td className="text-center">1,7</td>
                            <td className="text-center">232</td>
                            <td className="text-center">15</td>
                        </tr>
                        <tr>
                            <th>Scandic</th>
                            <td className="text-center">1,5</td>
                            <td className="text-center">123</td>
                            <td className="text-center">12</td>
                        </tr>*/}
					</tbody>
				</table>
			</>
		)
	}
}

export default DummyValuePerPartnerTable