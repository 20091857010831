import React from 'react'
import {Button} from 'reactstrap'
import ApiService from '../services/ApiService';

const AdminOverallMetricsJobs = () => {

    const handleSubmit = () => {
        const runJob = async () => {
            const res = await ApiService.runOverallMetricsJob();
            const status = res.status;
            if(status===200){
                console.log("Wahey")
            }
        }
        runJob();
    }

  return (
    <>        
        <div style={{display:'flex', alignItems:'center'}}>
            Update Overall Metrics
        </div>
        <Button color="primary" onClick={()=>handleSubmit()}>Run</Button>                            
    </>
  )
}

export default AdminOverallMetricsJobs