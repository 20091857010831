import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Nav, NavItem, NavLink, Spinner } from "reactstrap";
import classnames from "classnames";
import ApiService from "../services/ApiService";
import { format } from "date-fns";
import { connect } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTheme } from "../utils/useTheme";



const FollowersGraph = ({ date, useSourceFilter = false, total }) => {
  const { startDate, endDate } = date;
  const enabled = !!(startDate && endDate);
  let since, until;
  if (enabled) {
    since = format(startDate, "yyyy-MM-dd");
    until = format(endDate, "yyyy-MM-dd");
  }
  var darkTheme = true
  // check if light or dark mode
  if (useTheme() === "light-mode"){
    darkTheme = false
  }
  
const getChartOptions = (graphData, useSourceFilter, filter, total) => {
  var labelcolor = "#000"
  if (darkTheme === true) {
    labelcolor = "#fff"
  } 
  console.log("graphData", graphData)

  const series = useSourceFilter
    ? graphData.data.series.filter((serie) => serie.source === filter)
    : graphData.data.series;



  return {
    credits: { enabled: false },
    chart: {
      type: "spline",
    },
    title: {
      text: "",
    },
    yAxis: {
      title: {
        text: graphData.yTitle,
      },
      labels: {
        style: {
          color: labelcolor
       }
      }
      
    },
    xAxis: {
      categories: graphData.labels,
      labels: {
        rotation: 0,
        style: {
          color: labelcolor
       },
      },
      

      title: {
        text: graphData.xTitle,
      },
    },
    series,
  };
};

  const { data: graphData, isLoading, isRefetching, isSuccess, isError, error } = useQuery({
    queryKey: ["followers", startDate, endDate],
    queryFn: () => ApiService.getFollowers(since, until, false),
    select: (data) => {
      console.log("API Response:", data);  // Debug line
      return data;
    },
    enabled,
  });
  
  const [filter, setFilter] = useState("facebook");
  const [chartOptions, setChartOptions] = useState(null);
  
  useEffect(() => {
    if (graphData) { // Only call getChartOptions if graphData is defined
      const newChartOptions = getChartOptions(graphData, useSourceFilter, filter, total);
      setChartOptions(newChartOptions);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData, useSourceFilter, filter, total]);

  return (
    <>
      {isError && <div>{error.message}</div>}
      {(isLoading || isRefetching) && (
        <Spinner
          color="primary"
          size="sm"
          style={{ position: "absolute", right: 20 }}
        />
      )}
      {isSuccess && (
        <>
          {useSourceFilter && (
            <Nav pills className="navtab-bg my-4 center-pills">
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: filter === "facebook" })}
                  onClick={() => setFilter("facebook")}
                >
                  Facebook
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: filter === "instagram" })}
                  onClick={() => setFilter("instagram")}
                >
                  Instagram
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: filter === "google" })}
                  onClick={() => setFilter("google")}
                >
                  Google
                </NavLink>
              </NavItem>
            </Nav>
          )}
          {
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          }
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(FollowersGraph);
