import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'

/* Components */
import DemographicsChart from '../components/DemographicsChart'
import OverallMetric from '../components/OverallMetric'
import InfoTooltip from '../components/InfoTooltip'
import PerformingSocialMediaPosts from '../components/PerformingSocialMediaPosts'
import BestSocialMediaPostBubbles from '../components/BestSocialMediaPostBubbles';
import Geography from "../components/Geography";
import { withTranslation } from 'react-i18next'

const FansEliteserien = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <div className="container-fluid pt-4">
        <Row className="mb-4">
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              noTrend
              metric="seasontickets"
              tooltip={t('Viser totalt antall sesongkort som er solgt hittil i sesongen. Prosent-tallet viser trendutvikling sammenlignet med forrige måned.')}
              />
          </Col>
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              noTrend
              metric="tickets"
              tooltip={t('Viser totalt antall enkeltbilletter som er solgt hittil i sesongen. Prosent-tallet viser trendutvikling sammenlignet med forrige måned.')}
            />
          </Col>
          <Col xl={3} md={6}>
            <OverallMetric
              metric="followers"
              tooltip={t('Viser totalt antall følgere i sosiale kanaler. Prosent-tallet viser trendutvikling sammenlignet med forrige måned. Merk at det her kan være dobbeltdekning mellom Facebook og Instagram.')}
            />
          </Col>
          <Col xl={3} md={6}>
            <OverallMetric
              metric="newsletter-subscribers"
              tooltip={t('Viser antall e-postadresser registrert i Lime CRM. Prosent-tallet viser trendutvikling sammenlignet med forrige måned.')}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t('Alder og kjønn')}
                  <InfoTooltip tooltip={t('Viser demografisk nedbrytning av følgere i forhold til alder/livsfase og kjønn.')} />
                </h4>
                <DemographicsChart />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t('Geografi')}
                  <InfoTooltip tooltip={t('Viser kommunene der følgere bor. Dette er ikke komplett data, mange brukere har ikke lokasjonsdata tilgjengelig.')} />
                </h4>
                <Geography />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
          <OverallMetric followCalender noTrend metric="visibility-average-instagram" tooltip={t('Gjennomsnittlig antall serre som ser hver post publisert på instagram.')} />
          </Col> 
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric followCalender noTrend metric="visibility-average-facebook" tooltip={t('Gjennomsnittlig antall serre som ser hver post publisert på facebook.')} />
          </Col>
          <Col xl={3} md={6}>
            <OverallMetric followCalender noTrend metric="engagement-average-instagram" tooltip={t('Gjennomsnittlig antall likes og kommentarer på hver post publisert på instagram.')} />
          </Col>
          <Col xl={3} md={6}>
            <OverallMetric followCalender noTrend metric="engagement-average-facebook" tooltip={t('Gjennomsnittlig antall likes og kommentarer på hver post publisert på facebook.')} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={12}>
            <BestSocialMediaPostBubbles />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={12}>
            <PerformingSocialMediaPosts type="BESTE" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={12}>
            <PerformingSocialMediaPosts type="SVAKESTE" />
          </Col>
        </Row>
        {/*
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">Topp poster på sosiale medier siste måned</h4>
                <DummyTopSocialPosts />
              </CardBody>
            </Card>
          </Col>

          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">Når er det best å poste på sosiale medier</h4>
                <DummyHeatmapChart data={bestTimeToPost} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        */}
      </div>
    </>
  );
}


export default withTranslation()(FansEliteserien)
