import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers'
import thunk from 'redux-thunk'

const initialState = {
  tenant: localStorage.getItem('selectedTenantId') || false,
}
const middleware = [thunk]
const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middleware)
)

export default store