import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { setDate } from "../store/actions/dateActions";
import { useTranslation } from "react-i18next";

const NavbarPerson = ({ menuOpen, date, setDate }) => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 550);

  // Set initial state
  useEffect(() => {

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 550);
    };

    // Attach event listener
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="personnav">
        <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
          {menu(isMobile, t)}
        </nav>
      </div>
    </>
  );
};

const menu = (mobile, t) => {
  if (mobile) {
    return (
      <div id="mobile-nav">
        <ul className="person-nav">
          <li className="mobile-nav-item">
            <NavLink className="nav-link-person" to="/person">
              <i className="ti-pulse mr-2"></i>
            </NavLink>
          </li>
          <li className="mobile-nav-item">
            <NavLink className="nav-link-person" to="/person-socials">
              <i className="ti-user mr-2"></i>
            </NavLink>
          </li>
          <li className="mobile-nav-item">
            <NavLink className="nav-link-person" to="/person-audience">
              <i className="ti-headphone-alt mr-2"></i>
            </NavLink>
          </li>
          <li className="mobile-nav-item">
            <NavLink className="nav-link-person" to="/person-partner">
              <i className="ti-briefcase mr-2"></i>
            </NavLink>
          </li>
          <li className="mobile-nav-item">
            <NavLink className="nav-link-person" to="/person-chat">
              <i className="ti-comment"></i>
            </NavLink>
          </li>
        </ul>
      </div>
    );
  } else {
    return (
      <div id="navigation">
        <ul className="navbar-person-nav">
          <li className="nav-item">
            <NavLink className="nav-link-person" to="/person">
              <i className="ti-pulse mr-2"></i>
              {t("Person")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link-person" to="/person-socials">
              <i className="ti-user mr-2"></i>
              {t("Socials")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link-person" to="/person-audience">
              <i className="ti-headphone-alt mr-2"></i>
              {t("Audience")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link-person" to="/person-value">
              <i className="ti-briefcase mr-2"></i>
              {t("Value")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link-person" to="/person-chat">
              <i className="ti-comment mr-2"></i>
              {t("Chat")}
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
};
const mapStateToProps = (state) => ({
  date: state.date,
});

export default connect(mapStateToProps, { setDate })(NavbarPerson);
