import React from "react";
import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import DatePicker from "../components/DatePicker";
import { connect } from "react-redux";
import { setDate } from "../store/actions/dateActions";
import { useTranslation } from 'react-i18next'


const NavbarSponsor = ({ menuOpen, date, setDate }) => {
  const {t} = useTranslation();
  const { startDate, endDate } = date;
  const onChange = (dates) => {
    const [start, end] = dates;
    setDate({
      startDate: new Date(
        new Date(start).getFullYear(),
        new Date(start).getMonth(),
        1
      ),
      endDate: end
        ? new Date(new Date(end).getFullYear(), new Date(end).getMonth() + 1, 0)
        : undefined,
    });
  };
  return (
    <>
      <div className="topnav">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
            <Collapse isOpen={menuOpen} className="navbar-collapse">
              <div id="navigation">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to="/dashboard">
                      <i className="ti-pulse mr-2"></i>{t('Oversikt')}
                    </Link>
                  </li>
                  {/*<li className="nav-item">
                    <Link className="nav-link" to="/audience">
                      <i className="ti-user mr-2"></i>Målgruppe
                    </Link>
                  </li>*/}
                  <li className="nav-item">
                    <Link className="nav-link" to="/reports">
                      <i className="ti-clipboard mr-2"></i>{t('Rapport')}
                    </Link>
                  </li>
                </ul>
              </div>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                showMonthYearPicker
                dateFormat="MMM yy"
                confirm
                selectsRange
              />
            </Collapse>
          </nav>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
});

export default connect(mapStateToProps, { setDate })(NavbarSponsor);
