import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Button,
  CardTitle,
  CardText
} from "reactstrap";
import Switch from "react-switch";
import { FaExternalLinkAlt } from "react-icons/fa";
import { format } from "date-fns";
import SocialService from "../services/SocialService";
import { useToggleDataSourcesMutation } from "../query/hooks";

const providerToName = {
  facebook: "Facebook & Instagram",
  'facebook-business': "Facebook (Business)",
  x: "X",
  youtube: 'YouTube',
  tiktok: 'TikTok',
  google: 'Google',
}

const SocialState = ({ social }) => {
  const {accessTokens, provider} = social;
  const socialMediaName = providerToName?.[provider] ?? provider;
  const {mutate: toggleDataSourceMutation} = useToggleDataSourcesMutation();

  const providerToSocial = () => {
    switch (provider) {
      case 'facebook':
      case 'instagram':
        return SocialService.facebookLoginLink();
      case 'facebook-business':
        return SocialService.facebookBusinessLoginLink();
      case 'twitter':
        return SocialService.twitterLoginLink();
      default:
        return '#';
    }
  }


  return (
    <Container>
      <Row className="align-items-center">
        <Col xs="6">
          <h5>{ socialMediaName }</h5>
        </Col>
        <Col xs="6" className="text-right">
          <FormGroup className="social-form-group">
            <a href={providerToSocial()}>
              <Button
                className="btn btn-info ml-4 waves-effect waves-light"
                label="Connect"
              >
                Connect
              </Button>
            </a>
          </FormGroup>
        </Col>
      </Row>
      {accessTokens.map((accessToken) => (
        <Row className="mb-3">
          <Col>
            <Card>
              <CardBody>
                <CardTitle style={{marginBottom: '20px'}}>Connected accounts</CardTitle>
                {accessToken.accounts.map((account, index) => (
                  <div key={index} style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                    <Link style={{color: 'white', minWidth: 0 }} to={{ pathname: account.link}} target="_blank">
                      {account?.name}
                      <FaExternalLinkAlt style={{marginLeft: 10}} />
                    </Link>
                    <Switch
                      onColor="#02a499"
                      onChange={() => toggleDataSourceMutation({
                        provider: accessToken.provider,
                        accountProvider: account.provider,
                        accessTokenId: accessToken.id,
                        accountId: account.id,
                        newState: account.include ? 0 : 1
                      })}
                      checked={!!account?.include}
                    />
                  </div>
                ))}
                <CardText style={{ margin: 0 }}>
                  Expires at {format(new Date(accessToken.expires_at * 1000), "dd/MM/yyyy")}
                </CardText>
                <Row
                  className="justify-content-center"
                  style={{ padding: "10px" }}
                >
                  <Col xs="auto">
                    <Button color="danger" className="mr-2">
                      Disconnect
                    </Button>
                    <Button color="info" className="mr-2">
                      Test
                    </Button>
                    <Button color="primary">Sync</Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default SocialState;
