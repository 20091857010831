import React, { Component } from 'react'
import logo from '../assets/images/Logo_Foocus_Neg.png'

import DataSources from './DataSources'
class Onboarding extends Component {
  render() {
    return (
      <>
        <div className="onboarding-container">
            <div className="text-center">
                <img src={logo} width="200" className="onboarding-logo" alt="Foqus" />
                <h2 className="mb-3">Datakilder</h2>
                <p>Her kan du koble opp kilder du ønsker vi skal hente data fra. I første omgang Facebook, med tilknyttede Instagram-kontoer, og Google analytics-kontoer.</p>
                <p className="mb-5">Først legger du til lagets Facebook- og Google-kontoer. Deretter velger dere hvilke Facebook-sider, Instagram-kontoer og nettsider dere ønsker å inkludere data fra.</p>
                <a
                  href="https://app.foocus.ai"
                  className="btn btn-primary btn-block waves-effect waves-light"
                >
                  Hopp fram til dashbord
                </a>            
              </div>
            <DataSources />
        </div>
      </>
    )
  }
}

export default Onboarding
