import React from "react";
import ReactApexChart from "react-apexcharts";
const RadialChart = ({ percent }) => {
  const options = {
    plotOptions: {
      radialBar: {
        hollow: {
          size: "45%",
        },
        dataLabels: {
          value: {
            show: false,
          },
        },
      },
    },

    colors: ["rgb(16, 135, 171)", "#fff"],
    labels: [`${percent} % `],
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[percent]}
        type="radialBar"
        height="200"
      />
    </React.Fragment>
  );
};

export default RadialChart;
