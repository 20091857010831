import React, { Component } from 'react'
import { Tooltip } from 'reactstrap'
import _uniqueId from 'lodash/uniqueId'
class InfoTooltip extends Component {

	constructor(props) {
		super(props)
		this.state = {
            open: false,
            id: _uniqueId("tooltip-")
		}
	}

	render() {
        const { open, id } = this.state
        const { tooltip } = this.props
		return (
			<>
                <button type="button" className="btn btn-tooltip" id={id}>i</button>
                <Tooltip placement="bottom" isOpen={open} target={id} toggle={()=> this.setState({ open: !open })}>{tooltip}</Tooltip>
			</>
		)
	}
}

export default InfoTooltip