import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, Dropdown } from "reactstrap";
import { usePartners } from "../query/hooks";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const PartnerLink = () => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const {data: partners, isLoading, isRefetching} = usePartners();

	const location = useLocation();
	const selectedPartnerId = new URLSearchParams(location.search).get('partner');

	const isPartnerPage = location.pathname === '/partnere';

	const toggle = () => setIsOpen(prev => !prev);

	return (
		<li>
			<Dropdown isOpen={isOpen} toggle={toggle} className="m-0">
				<Link to="/partnere">
					<DropdownToggle color="transparent" className="nav-link">
						<i className="ti-briefcase mr-2"></i>{t('Partnere')}
						<span className="mdi mdi-chevron-down"></span>
						{
              (isLoading || isRefetching) && <Spinner className="ml-2" size="sm" />
            }
					</DropdownToggle>
				</Link>
				<DropdownMenu>
					<Link to="/partnere">
						<DropdownItem active={isPartnerPage && !selectedPartnerId}>
							{t("Alle")}
						</DropdownItem>
					</Link>
					{partners?.map(partner => (
						<Link key={`partner-${partner.id}`} to={`/partnere?partner=${partner.id}`}>
							<DropdownItem active={isPartnerPage && +selectedPartnerId === partner.id}>{partner.name}</DropdownItem>
						</Link>
          ))}
				</DropdownMenu>
			</Dropdown>
		</li>

	)
}

export default PartnerLink;
