import React from 'react'
import { Collapse } from 'reactstrap'
import { NavLink, withRouter } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import PartnerLink from '../components/PartnerLink';

const NavbarToppserien = (props) => {
  const {t} = useTranslation();

    return (
      <>
        <div className="topnav">
          <div className="container-fluid">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
              <Collapse
                isOpen={props.menuOpen}
                className="navbar-collapse"
              >
                <div id="navigation">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/dashboard">
                        <i className="ti-pulse mr-2"></i>{t('Oversikt')}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/fans">
                        <i className="ti-headphone-alt mr-2"></i>{t('Media')}
                      </NavLink>
                    </li>
                    <PartnerLink />
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/brand">
                        <i className="ti-heart mr-2"></i>{t('Merkevare')}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/reports">
                        <i className="ti-clipboard mr-2"></i>{t('Rapport')}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </nav>
          </div>
        </div>
      </>
    );
  }


export default withRouter(NavbarToppserien)
