import classNames from 'classnames';
import React from 'react';

const Filter = ({options, setOptions}) => {
	return (
		<div className="d-flex filter">
			{options.map((option) =>
				<button
					key={`filter-${option.name}`}
					className={classNames('d-flex', 'filter-item', 'align-items-center', 'mr-4', {'filter-selected': option.selected})}
					onClick={() => {
						setOptions(options.map(_option => ({
							..._option,
							selected: _option.name === option.name ? !_option.selected : _option.selected,
						})));
					}}
				>
					<div className="filter-circle mr-2" style={{backgroundColor: option.color}} />
					<p>{option.name}</p>
				</button>
			)}
		</div>
	);
};

export default Filter;
