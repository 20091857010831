import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { ALL_BRANDS } from "../keys";

const useAllBrands = () => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [ALL_BRANDS, tenant.id],
    queryFn: ApiService.getAllBrands,
    select: (data) => data?.data,
    placeholderData: { data: [] },
  });
};
export default useAllBrands;
