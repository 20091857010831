import { SET_USER_TENANT, SET_LOADING, SET_USER, SET_IS_AUTHENTICATED, SET_ACCESS_TOKEN, CREATE_AUTH_CLIENT, LOGIN_USER, LOGOUT_USER, FETCH_USER_TENANTS_ERROR, FETCH_USER_TENANTS_SUCCESS, FETCH_USER_TENANTS_PENDING } from './types';
import axios from 'axios'
import ApiService from '../../services/ApiService'
export const setLoading = loading => dispatch => {
    dispatch({
        type: SET_LOADING,
        payload: loading
    })
}

export const setIsAuthenticated = is_authenticated => dispatch => {
    dispatch({
        type: SET_IS_AUTHENTICATED,
        payload: is_authenticated
    })
}

export const setAccessToken = access_token => dispatch => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
    dispatch({
        type: SET_ACCESS_TOKEN,
        payload: access_token
    })
}

export const createAuthClient = client => dispatch => {
    dispatch({
        type: CREATE_AUTH_CLIENT,
        payload: client
    })
}

export const setUserTenant = tenant => dispatch => {
    axios.defaults.headers.common['User-Tenant'] = `${tenant.id}`
    localStorage.setItem('selectedTenantId', tenant.id);
    dispatch({
        type: SET_USER_TENANT,
        payload: tenant
    })
}

export const loginUser = user => dispatch => {
    dispatch({
        type: LOGIN_USER,
        payload: user
    })
}

export const logoutUser = () => dispatch => {
    dispatch({
        type: LOGOUT_USER,
        payload: null
    })
}

export const fetchUserTenantsPending = () => dispatch => {
    dispatch({
        type: FETCH_USER_TENANTS_PENDING
    })
}

export const fetchUserTenantsSuccess = tenants => dispatch => {
    dispatch({
        type: FETCH_USER_TENANTS_SUCCESS,
        payload: tenants
    })
}

export const fetchUserTenantsError = error => dispatch => {
    dispatch({
        type: FETCH_USER_TENANTS_ERROR,
        payload: error
    })
}

export const getUser = () => dispatch => {
    ApiService.getUser().then((resp) => {
        const payload = resp && resp.data
        dispatch({
            type: SET_USER,
            payload: payload
        })
    }, (err) => {
        // TODO: Handle error here
    })
    
}

