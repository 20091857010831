import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
// Custom Scrollbar

/* Components */
import TvViewersGraph from '../components/TvViewersGraph'
import OverallMetric from '../components/OverallMetric'
import PressCountBarChart from '../components/PressCountBarChart'
import TvViewers from '../components/TvViewers'
import BrandSources from '../components/BrandSources'
import InfoTooltip from '../components/InfoTooltip'
import { useTranslation } from 'react-i18next'



const Media = () => {
	const { t } = useTranslation();
	return (
		<>
			<div className="container-fluid pt-4">
				<Row className="mb-4">
					<Col xl={3} md={6} className="mb-4 mb-xl-0">
						<OverallMetric
							followCalender
							metric="tv-rating"
							tooltip={t('Totalt antall TV-seere som har sett fulle kamper så langt i inneværende år.')}
							noTrend
						/>
					</Col>
					<Col xl={3} md={6} className="mb-4 mb-xl-0">
						<OverallMetric
							followCalender
							metric="tv-coverage"
							tooltip={t('Totalt antall TV-seere som har sett mer enn 1 minutt av kampene så langt i inneværende år.')}
							noTrend
						/>
					</Col>
					<Col xl={3} md={6}>
						<OverallMetric
							followCalender
							metric="press-mentions"
							tooltip={t('Totalt antall saker i riks- og lokalaviser enten på nett eller i print hvor klubben er omtalt så langt i inneværende år.')}
							noTrend
						/>
					</Col>
					<Col xl={3} md={6}>
						<OverallMetric
							followCalender
							metric="sentiment"
							tooltip={t('Andel av pressesakene som er blitt registrert som positiv omtale. Ofte er ca 40-70% av pressesakene registrert som “nøytrale”. Andel positiv omtale vil derfor sjelden være mer enn 50%.')}
							noTrend
							numeralFormat="0%"
							variant="ratio-bar"
						/>
					</Col>
				</Row>
				<Row className="mb-4">
					<Col sm={12} md={6}>
						<Card className="mini-stat bg-card text-white">
							<CardBody>
								<h4 className="card-title mb-4">{t('TV seere')}</h4>
								<TvViewersGraph />
							</CardBody>
						</Card>
					</Col>
					<Col sm={12} md={6}>
						<Card className="mini-stat bg-card text-white">
							<CardBody>
								<h4 className="card-title mb-4">
									{t('Presseomtale')}
									<InfoTooltip tooltip={t('Viser antall saker i riksdekkende og lokale nyhetsmedierhvor klubb, trener, eller arena er nevnt i forbindelse med fotball eller liga.')} />
								</h4>
								<BrandSources />
							</CardBody>
						</Card>
					</Col>
				</Row>

				<Row className="mb-4">
					<Col sm={12} md={6}>
						<Card className="mini-stat bg-card text-white">
							<CardBody>
								<h4 className="card-title mb-4">{t('TV seere per oppgjør')}</h4>
								<TvViewers />
							</CardBody>
						</Card>
					</Col>
					<Col sm={12} md={6}>
						<Card className="mini-stat bg-card text-white">
							<CardBody>
								<h4 className="card-title mb-4">
									{t('Omtale per medie')}
									<InfoTooltip tooltip={t('Viser medieomtale og presseoppslag fordelt på ulike print (merket PDF) og nettaviser. Saker bak betalingsmur er merket med "login" eller "pluss".')} />
								</h4>
								<PressCountBarChart />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default Media
