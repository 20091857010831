import React from 'react'
import { Progress } from 'reactstrap'
import '../sass-light/custom/components/_ratiobar.scss'

const RatioBar = ({ positive, neutral, negative }) => {
	console.log('positive', positive)
	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<h4 className="font-weight-medium font-size-24" style={{ marginBottom: 0 }}>
					{Math.round(positive)}%
				</h4>
				<h4
					className="font-weight-medium font-size-24"
					style={{ marginLeft: 'auto', marginBottom: 0 }}>
					{Math.round(negative)}%
				</h4>
			</div>
			<Progress multi style={{ height: '5px' }}>
				<Progress bar value={positive} color="positive" />
				<Progress bar value={neutral} color="neutral" />
				<Progress bar value={negative} color="negative" />
			</Progress>
		</>
	)
}

export default RatioBar
