import React from 'react'
import { connect } from 'react-redux'


const PersonChat = () => {


    return (
        <>

            
        </>
    );
}

const mapStateToProps = state => ({
  tenant: state.auth.tenant
})

export default connect(mapStateToProps, {})(PersonChat)
