import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { connect } from "react-redux";
import { setDate } from "../store/actions/dateActions";

import Sidebar from "react-sidebar";
import { FaBars } from 'react-icons/fa';
import { AiFillHome, AiFillSetting, AiOutlineLogout } from 'react-icons/ai';
import { BsFillPersonFill, BsFillBarChartFill } from 'react-icons/bs';
import { RiDashboardFill, RiGroupLine } from 'react-icons/ri';
import foocusLogo from '../assets/images/foocusLogo.svg'
import TenantDropdown from "../components/TenantDropdown";
import LanguageSelect from "../components/LanguageSelect";


const AthleteTopBar = ({
  theme,
  isMenuOpened,
  toggleRightSidebar,
  openLeftMenuCallBack,
  tenant,
  menuOpen,
  date,
  setDate,
  client,
}) => {
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

    const logout = () => {
        client.logout();
    }

    const sidebarContent = (
        <div style={{ backgroundColor: '#387A9E', height: '100%', color: 'white' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={foocusLogo} alt="Foocus Logo" style={{ width: '120px', height: '120px', borderRadius: '0', marginTop: '20px', marginBottom: '40px' }} />
            </div>
          <ul style={{ listStyleType: 'none', marginLeft: '0px', padding: 0 }}>
            <li style={{ paddingLeft: '10px' }}>
                <Link to="/dashboard" style={{ color: 'white', textDecoration: 'none' }}>
                    <BsFillPersonFill style={{ margin: '8px'}} /> Profile
                </Link>
            </li>
            <hr style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', border: 0, height: '1px', width: '100%', left: 0 }}/>
            
            <li style={{ paddingLeft: '10px' }}>
                <Link to="/performance" style={{ color: 'white', textDecoration: 'none' }}>
                    <RiDashboardFill style={{ margin: '8px'}}/> Performance
                </Link>        
            </li>
            <hr style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', border: 0, height: '1px', width: '100%', left: 0 }}/>

            <li style={{ paddingLeft: '10px' }}>
                <Link to="/value" style={{ color: 'white', textDecoration: 'none' }}>
                    <BsFillBarChartFill style={{ margin: '8px'}}/> Value
                </Link>
            </li>
            {/* <hr style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', border: 0, height: '1px', width: '100%', left: 0 }}/> */}

            {/* <li style={{ paddingLeft: '10px' }}>
                <Link to="/genie" style={{ color: 'white', textDecoration: 'none' }}>
                    <RiFileChart2Line style={{ margin: '8px'}}/> Genie
                </Link>
            </li> */}
            <hr style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', border: 0, height: '1px', width: '100%', left: 0 }}/>

            <li style={{ paddingLeft: '10px' }}>
                <Link to="/audience" style={{ color: 'white', textDecoration: 'none' }}>
                    <RiGroupLine style={{ margin: '8px'}}/> Audience
                </Link>
            </li>
            <hr style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', border: 0, height: '1px', width: '100%', left: 0 }}/>

            <li style={{ paddingLeft: '10px' }}>
                <Link to="/reports" style={{ color: 'white', textDecoration: 'none' }}>
                    <AiFillHome style={{ margin: '8px'}}/> Reports
                </Link>
            </li>
            <hr style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', border: 0, height: '1px', width: '100%', left: 0 }}/>

            <li style={{ paddingLeft: '10px' }}>
                <Link to="/settings" style={{ color: 'white', textDecoration: 'none' }}>
                    <AiFillSetting style={{ margin: '8px'}}/> Settings
                </Link>
            </li>
            <hr style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', border: 0, height: '1px', width: '100%', left: 0 }}/>

            <li style={{ paddingLeft: '10px' }}>
                <Link to="#" onClick={() => logout()} style={{ color: 'white', textDecoration: 'none' }}>
                    <AiOutlineLogout style={{ margin: '8px'}}/> Logout
                </Link>
             </li>
          </ul>
        </div>
      );


    const getPageName = () => {
        switch (location.pathname) {
            case '/dashboard':
                return 'Profile';
            case '/value':
                return 'Value';
            case '/performance':
            case '/performance/source':
                return 'Performance';
            case '/audience':
                return 'Audience';
            case '/settings':
                return 'Settings';
            // Add more cases as needed
            default:
            return 'Home';
        }
    }

    return (
        <header id="page-topbar" style={{ backgroundColor: '#201D30' }}>
            <Sidebar
                sidebar={sidebarContent}
                open={sidebarOpen}
                onSetOpen={setSidebarOpen}
                styles={{ sidebar: { background: "white", width: '250px', height: '100vh', position: 'fixed' } }}
            >
                <button onClick={toggleSidebar} style={{ 
                    backgroundColor: '#2A2839', 
                    color: '#E4BE50', 
                    height: '40px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    marginLeft: '20px',
                    marginTop: '10px',
                    border: '1px solid #2A2839',
                    borderRadius: '10%',
                    width: '40px',
                }}
                >
                    <FaBars />
                </button>
            </Sidebar>
            <div className="navbar-header" style={{ justifyContent: 'center' }}>
                <div style={{flex: 1}}></div>
                    <div className="navbar-brand-box">
                    <h3>{getPageName()}</h3>
                </div>
                <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
                    <LanguageSelect />
                    <TenantDropdown variant="athlete" />
                </div>
            </div>
        </header>
    );
};

const mapStateToProps = (state) => ({
    tenant: state.auth.tenant,
    isFullscreenEnabled: state.isFullscreenEnabled,
    date: state.date,
    client: state.auth.client,
});

export default connect(mapStateToProps, { setDate })(AthleteTopBar);
