import React, { forwardRef, useRef, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { nb } from 'date-fns/locale'
import { connect } from "react-redux";
import { Button } from 'reactstrap';

registerLocale("nb", nb); // register it with the name you want


const DatePickerInput = forwardRef(({ value, onClick }, ref) => {
  return (
    <button className="datepicker-button" onClick={onClick} ref={ref}>
      <i className="ti-calendar"></i>
      {value}
      <i style={{ opacity: 0}} className="ti-calendar"></i>
    </button>
  )
});

const DatePicker = ({ startDate, onChange, endDate, dateFormat = 'MMM yy', showMonthYearPicker, selectsRange, tenant, confirm }) => {
  const dateRef = useRef();
  const [localDate, setLocalDate] = useState({
    startDate: startDate,
    endDate: endDate
  });

  var minDate = new Date(new Date().getFullYear() - 2, 0, 1);
  if (tenant.name === "Bama") {
    minDate = new Date(new Date().getFullYear(), -1, 1);
  }

  const onLocalChange = (...args) => {
    const dates = args[0];
    setLocalDate({
      startDate: dates[0],
      endDate: dates[1]
    });
    if (confirm) {
      return;
    }

    onChange(...args);
  }

  const onConfirmChange = () => {
    if (localDate.startDate === startDate && localDate.endDate === endDate) {
      return;
    }
    if (!localDate.startDate || !localDate.endDate) {
      return;
    }
    onChange([localDate.startDate, localDate.endDate]);
    dateRef.current.setOpen(false);
  }

  const onClickOutside = () => {
    if (confirm && localDate.startDate && localDate.endDate) {
      onConfirmChange([localDate.startDate, localDate.endDate]);
    } else {
      reset();
    }
  }

  const onCancel = () => {
    reset();
    dateRef.current.setOpen(false);
  }


  const reset = () => {
    setLocalDate({
      startDate: startDate,
      endDate: endDate
    });
  }

  return (
    <ReactDatePicker
      className="datepicker"
      onChange={onLocalChange}
      startDate={localDate.startDate}
      endDate={localDate.endDate}
      shouldCloseOnSelect={false}
      showMonthYearPicker={showMonthYearPicker}
      dateFormat={dateFormat}
      selectsRange={selectsRange}
      locale="en"
      minDate={minDate}
      maxDate={new Date(new Date().getFullYear(), 11, 31)}
      customInput={<DatePickerInput />}
      onClickOutside={onClickOutside}
      showFourColumnMonthYearPicker
      ref={dateRef}
    >
      { confirm && (
        <div className="react-datepicker__children-container-buttons">
          <Button
            onClick={onCancel}
            color="primary"
            outline
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={onConfirmChange}
            disabled={localDate.startDate  && localDate.endDate ? false : true}
          >
            Apply
          </Button>
        </div>
      )}
    </ReactDatePicker>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  selectedCurrency: state.currency.selectedCurrency,
    tenant: state.auth.tenant,
  });

export default connect(mapStateToProps)(DatePicker);