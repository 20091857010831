import React, { Component } from 'react'
import { connect } from 'react-redux'

/* Components */
import FansEliteserien from './FansEliteserien'
import FansToppserien from './FansToppserien'

class Fans extends Component {
  render() {
    const { tenant } = this.props
    console.log('tenant', tenant)
    return (
      <>
        {
          {
            'eliteserien': <FansEliteserien />,
            'obosligaen': <FansEliteserien />,
            'toppserien': <FansToppserien />
          }[tenant.template]
        }
      </>
    );
  }
}

const mapStateToProps = state => ({
  tenant: state.auth.tenant
})

export default connect(mapStateToProps, {})(Fans)