import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const CallbackPage = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  useEffect(() => {
    const handleAuthentication = async () => {
      try {
        if (!isAuthenticated) {
          await loginWithRedirect();
        }
      } catch (error) {
        console.error('Authentication error:', error);
      }
    };

    handleAuthentication();
  }, [isAuthenticated, loginWithRedirect]);

  return (
    <div>
      {/* You can display a loading spinner or a message here */}
      <p>Redirecting...</p>
    </div>
  );
};

export default CallbackPage;
