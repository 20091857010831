import { fetchUserTenantsPending, fetchUserTenantsSuccess, fetchUserTenantsError} from './authActions'

import ApiService from '../../services/ApiService'

function fetchTenants() {
    return dispatch => {
        dispatch(fetchUserTenantsPending())

        return ApiService.getTenants()
        .then(res => {
            const payload = res && res.data
            dispatch(fetchUserTenantsSuccess(payload))
        })
        .catch(error => {
            dispatch(fetchUserTenantsError(error))
        })
    }
}

export default fetchTenants