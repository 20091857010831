import { FaRegEdit } from "react-icons/fa";


const PersonOverview = ({name, title, image}) => {
	return (
		<div className="mb-4">
      <div className="mb-2" style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{position: 'relative', width: 138, height: 138}}>
          <img
            src={image}
            alt=""
            style={{width: 138, height: 138, objectFit: 'cover', borderRadius: '50%', border: '3px solid #377B9E'}}
          />
          <div
            style={{
              width: 27,
              height: 27,
              backgroundColor: '#377B9E',
              borderRadius: '50%',
              position: 'absolute',
              bottom: 10,
              right: 5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <FaRegEdit color="white" />
          </div>
        </div>
      </div>
      <p className="text-center font-color-white m-0">{name}</p>
      <p className="text-center m-0">{title}</p>
    </div>
);
}

export default PersonOverview;
