import React from 'react';
import {
  Card,
  CardBody,
} from 'reactstrap';
import logo from '../assets/images/Logo_Foocus_Neg.png';

function EmailNotificationPage() {

  return (
    <>
        <div className="accountbg" />

        <div className="wrapper-page account-page-full">
          <Card>
            <CardBody>
              <h3 className="text-center">
                <img src={logo} height="75" alt="logo" />
              </h3>

              <div className="p-3">
                <h4 className="font-18 m-b-5 text-center">Email Verification</h4>
                <p></p>
                <p className="text-muted text-center">
                  An email has been sent with further instructions.
                </p>
              </div>
            </CardBody>
          </Card>

          <div className="m-t-40 text-center">
            <p>
              Didn't get an email?{' '}
                Contact support@foocus.ai{' '}
            </p>
            <p>
              © {new Date().getFullYear()} Foocus AS
            </p>
          </div>
        </div>
      </>
  )
}

export default EmailNotificationPage;
