import React, { useState } from "react";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from "highcharts-react-official";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import ApiService from "../services/ApiService";
import { connect } from "react-redux";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "../utils/useTheme";


const TvViewersGraph = ({ date, tenant }) => {
  const [activeTab, setActiveTab] = useState(1);// default to monthly
  var darkTheme = true
  // check if light or dark mode
  if (useTheme() === "light-mode"){
    darkTheme = false
  }
  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }
  const {
    data: graphs,
    isLoading,
    isRefetching,
    isSuccess,
  } = useQuery({
    queryKey: ["tvEngagement", tenant.id, startDate, endDate],
    queryFn: () => ApiService.getTvEngagement(since, until),
    select: (data) => data.data.graphs,
    enabled,
    placeholderData: { data: { graphs: [] } },
  });

  const chartOptions = (graph) => {
    var backgroundColor = "#fff"
    var labelcolor = "#000"
    if (darkTheme === true) {
      backgroundColor = "#2B283B"
      labelcolor = "#fff"
    } 
    console.log(graph.data.series)

    return {
      credits: { enabled: false },
      chart: {
        type: "spline",
        backgroundColor: backgroundColor,  // <-- Add background color

      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: graph.data.yTitle,
        },
        labels: {
          style: {
            color: labelcolor
         }
        }
      },
      xAxis: {
        categories: graph.data.labels,
        labels: {
          style: {
            color: labelcolor
         }
        },
        title: {
          text: graph.data.xTitle,
        },
      },
      series: graph.data.series,
    };
  };
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const renderTab = (graph, index) => {
    return (
      <NavItem key={index}>
        <NavLink
          href="#"
          className={classnames({ active: activeTab === index })}
          onClick={() => toggleTab(index)}
        >
          {graph.source}
        </NavLink>
      </NavItem>
    );
  };

  const renderSource = (graph, index) => {
    return (
      <TabPane tabId={index} className="p-3" key={index}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions(graph)}
        />
      </TabPane>
    );
  };

  if (isLoading) {
    return <></>;
  }
  return (
    <>
      {(isLoading || isRefetching) && <Spinner color="primary" />}
      {isSuccess && (
        <>
          <Nav pills className="navtab-bg my-4 center-pills">
            {graphs.map((graph, index) => renderTab(graph, index))}
          </Nav>
          <TabContent activeTab={activeTab}>
            {graphs.map((graph, index) => renderSource(graph, index))}
          </TabContent>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(TvViewersGraph);
