import React from "react";
import { Row, Col, Spinner } from "reactstrap";
import { connect } from "react-redux";
import BoxedMetric from "../components/BoxedMetric";
import SocialMediaMetric from "../components/SocialMediaMetric";
import PersonOverview from "../components/PersonOverview";
import numeral from "numeral";
import usePersonSocialMetrics from "../query/hooks/usePersonSocialMetrics";
import useTenantProfileData from "../query/hooks/useTenantProfileData";
import { useTranslation } from "react-i18next";


const DashboardPerson = ({ selectedCurrency, tenant }) => {
  const {data: profile, isLoading: isLoadingProfile} = useTenantProfileData();
  const {data: socialMetrics, isLoading: isLoadingMetrics} = usePersonSocialMetrics();
  const { t } = useTranslation();

  const primarySocialFn = (primary) => {
    return `${numeral(primary).format('0.0a')}`;
  }

  return (
      <div className="container-fluid pt-4 p-0">
        {isLoadingProfile ? (
          <div className="mb-4">
            <div className="mb-2" style={{display: 'flex', justifyContent: 'center'}}>
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '160px', width: '160px', backgroundColor: '#302D41', borderRadius: '50%' }}>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </Row>
            </div>
          </div>
        ): (
          <PersonOverview
            image={profile?.imageUrl}
            name={profile?.name}
            title={profile?.shortDescription}
          />
        )}

        <Row className="mb-4">
          <Col xs={4}>
            <BoxedMetric metric="commerical-value" titleFn={primarySocialFn} secondaryFn={() => t('Commerical value')} />
          </Col>
          <Col xs={4}>
            <BoxedMetric metric="total-followers" titleFn={primarySocialFn} secondaryFn={() => t('Followers')} />
          </Col>
          <Col xs={4}>
            <BoxedMetric metric="posts-count" titleFn={primarySocialFn} secondaryFn={() => t('Posts')} />
          </Col>
        </Row>

        <Row className="mb-4">
          {isLoadingMetrics && <Spinner color="primary" />}
          {socialMetrics && socialMetrics.map((metric) => (
            <Col xs={12} md={6} lg={4} key={metric.source} className="mb-4">
              <SocialMediaMetric source={metric.source} title="Engagement Rate" value={numeral(metric.follower_count).format('0a')} growth={numeral(metric.growth).format('0.00%')} />
            </Col>
          ))}
        </Row>
      </div>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  selectedCurrency: state.currency.selectedCurrency,
	tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(DashboardPerson);
